@mixin fa-icon($icon-name, $align: before) {
  @extend %fa-base-styles;
  @extend %fa-icon-#{$icon-name}; }

%fa-base-styles {
  display: inline-block;
  font: normal normal normal #{$body-icon-font-size}/1 "Font Awesome 5 Pro";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

%fa-icon-hourglass {
  content: "\f254"; }

%fa-icon-adjust {
  content: "\f042"; }

%fa-icon-adn {
  content: "\f170"; }

%fa-icon-align-center {
  content: "\f037"; }

%fa-icon-align-justify {
  content: "\f039"; }

%fa-icon-align-left {
  content: "\f036"; }

%fa-icon-align-right {
  content: "\f038"; }

%fa-icon-ambulance {
  content: "\f0f9"; }

%fa-icon-anchor {
  content: "\f13d"; }

%fa-icon-android {
  content: "\f17b"; }

%fa-icon-angellist {
  content: "\f209"; }

%fa-icon-angle-double-down {
  content: "\f103"; }

%fa-icon-angle-double-left {
  content: "\f100"; }

%fa-icon-angle-double-right {
  content: "\f101"; }

%fa-icon-angle-double-up {
  content: "\f102"; }

%fa-icon-angle-down {
  content: "\f107"; }

%fa-icon-angle-left {
  content: "\f104"; }

%fa-icon-angle-right {
  content: "\f105"; }

%fa-icon-angle-up {
  content: "\f106"; }

%fa-icon-apple {
  content: "\f179"; }

%fa-icon-archive {
  content: "\f187"; }

%fa-icon-area-chart {
  content: "\f1fe"; }

%fa-icon-arrow-circle-down {
  content: "\f0ab"; }

%fa-icon-arrow-circle-left {
  content: "\f0a8"; }

%fa-icon-arrow-circle-o-down {
  content: "\f01a"; }

%fa-icon-arrow-circle-o-left {
  content: "\f190"; }

%fa-icon-arrow-circle-o-right {
  content: "\f18e"; }

%fa-icon-arrow-circle-o-up {
  content: "\f01b"; }

%fa-icon-arrow-circle-right {
  content: "\f0a9"; }

%fa-icon-arrow-circle-up {
  content: "\f0aa"; }

%fa-icon-arrow-down {
  content: "\f063"; }

%fa-icon-arrow-left {
  content: "\f060"; }

%fa-icon-arrow-right {
  content: "\f061"; }

%fa-icon-arrow-up {
  content: "\f062"; }

%fa-icon-arrows {
  content: "\f047"; }

%fa-icon-arrows-alt {
  content: "\f0b2"; }

%fa-icon-arrows-h {
  content: "\f07e"; }

%fa-icon-arrows-v {
  content: "\f07d"; }

%fa-icon-asterisk {
  content: "\f069"; }

%fa-icon-at {
  content: "\f1fa"; }

%fa-icon-automobile {
  content: "\f1b9"; }

%fa-icon-backward {
  content: "\f04a"; }

%fa-icon-ban {
  content: "\f05e"; }

%fa-icon-bank {
  content: "\f19c"; }

%fa-icon-bar-chart {
  content: "\f080"; }

%fa-icon-bar-chart-o {
  content: "\f080"; }

%fa-icon-barcode {
  content: "\f02a"; }

%fa-icon-bars {
  content: "\f0c9"; }

%fa-icon-bed {
  content: "\f236"; }

%fa-icon-beer {
  content: "\f0fc"; }

%fa-icon-behance {
  content: "\f1b4"; }

%fa-icon-behance-square {
  content: "\f1b5"; }

%fa-icon-bell {
  content: "\f0f3"; }

%fa-icon-bell-o {
  content: "\f0a2"; }

%fa-icon-bell-slash {
  content: "\f1f6"; }

%fa-icon-bell-slash-o {
  content: "\f1f7"; }

%fa-icon-bicycle {
  content: "\f206"; }

%fa-icon-binoculars {
  content: "\f1e5"; }

%fa-icon-birthday-cake {
  content: "\f1fd"; }

%fa-icon-bitbucket {
  content: "\f171"; }

%fa-icon-bitbucket-square {
  content: "\f172"; }

%fa-icon-bitcoin {
  content: "\f15a"; }

%fa-icon-bold {
  content: "\f032"; }

%fa-icon-bolt {
  content: "\f0e7"; }

%fa-icon-bomb {
  content: "\f1e2"; }

%fa-icon-book {
  content: "\f02d"; }

%fa-icon-bookmark {
  content: "\f02e"; }

%fa-icon-bookmark-o {
  content: "\f097"; }

%fa-icon-briefcase {
  content: "\f0b1"; }

%fa-icon-btc {
  content: "\f15a"; }

%fa-icon-bug {
  content: "\f188"; }

%fa-icon-building {
  content: "\f1ad"; }

%fa-icon-building-o {
  content: "\f0f7"; }

%fa-icon-bullhorn {
  content: "\f0a1"; }

%fa-icon-bullseye {
  content: "\f140"; }

%fa-icon-bus {
  content: "\f207"; }

%fa-icon-buysellads {
  content: "\f20d"; }

%fa-icon-cab {
  content: "\f1ba"; }

%fa-icon-calculator {
  content: "\f1ec"; }

%fa-icon-calendar {
  content: "\f073"; }

%fa-icon-calendar-o {
  content: "\f133"; }

%fa-icon-camera {
  content: "\f030"; }

%fa-icon-camera-retro {
  content: "\f083"; }

%fa-icon-car {
  content: "\f1b9"; }

%fa-icon-caret-down {
  content: "\f0d7"; }

%fa-icon-caret-left {
  content: "\f0d9"; }

%fa-icon-caret-right {
  content: "\f0da"; }

%fa-icon-caret-square-o-down {
  content: "\f150"; }

%fa-icon-caret-square-o-left {
  content: "\f191"; }

%fa-icon-caret-square-o-right {
  content: "\f152"; }

%fa-icon-caret-square-o-up {
  content: "\f151"; }

%fa-icon-caret-up {
  content: "\f0d8"; }

%fa-icon-cart-arrow-down {
  content: "\f218"; }

%fa-icon-cart-plus {
  content: "\f217"; }

%fa-icon-cc {
  content: "\f20a"; }

%fa-icon-cc-amex {
  content: "\f1f3"; }

%fa-icon-cc-discover {
  content: "\f1f2"; }

%fa-icon-cc-mastercard {
  content: "\f1f1"; }

%fa-icon-cc-paypal {
  content: "\f1f4"; }

%fa-icon-cc-stripe {
  content: "\f1f5"; }

%fa-icon-cc-visa {
  content: "\f1f0"; }

%fa-icon-certificate {
  content: "\f0a3"; }

%fa-icon-chain {
  content: "\f0c1"; }

%fa-icon-chain-broken {
  content: "\f127"; }

%fa-icon-check {
  content: "\f00c"; }

%fa-icon-check-circle {
  content: "\f058"; }

%fa-icon-check-circle-o {
  content: "\f05d"; }

%fa-icon-check-square {
  content: "\f14a"; }

%fa-icon-check-square-o {
  content: "\f046"; }

%fa-icon-chevron-circle-down {
  content: "\f13a"; }

%fa-icon-chevron-circle-left {
  content: "\f137"; }

%fa-icon-chevron-circle-right {
  content: "\f138"; }

%fa-icon-chevron-circle-up {
  content: "\f139"; }

%fa-icon-chevron-down {
  content: "\f078"; }

%fa-icon-chevron-left {
  content: "\f053"; }

%fa-icon-chevron-right {
  content: "\f054"; }

%fa-icon-chevron-up {
  content: "\f077"; }

%fa-icon-child {
  content: "\f1ae"; }

%fa-icon-circle {
  content: "\f111"; }

%fa-icon-circle-o {
  content: "\f10c"; }

%fa-icon-circle-o-notch {
  content: "\f1ce"; }

%fa-icon-circle-thin {
  content: "\f1db"; }

%fa-icon-clipboard {
  content: "\f0ea"; }

%fa-icon-clock-o {
  content: "\f017"; }

%fa-icon-close {
  content: "\f00d"; }

%fa-icon-cloud {
  content: "\f0c2"; }

%fa-icon-cloud-download {
  content: "\f0ed"; }

%fa-icon-cloud-upload {
  content: "\f0ee"; }

%fa-icon-cny {
  content: "\f157"; }

%fa-icon-code {
  content: "\f121"; }

%fa-icon-code-fork {
  content: "\f126"; }

%fa-icon-codepen {
  content: "\f1cb"; }

%fa-icon-coffee {
  content: "\f0f4"; }

%fa-icon-cog {
  content: "\f013"; }

%fa-icon-cogs {
  content: "\f085"; }

%fa-icon-columns {
  content: "\f0db"; }

%fa-icon-comment {
  content: "\f075"; }

%fa-icon-comment-o {
  content: "\f0e5"; }

%fa-icon-comments {
  content: "\f086"; }

%fa-icon-comments-o {
  content: "\f0e6"; }

%fa-icon-compass {
  content: "\f14e"; }

%fa-icon-compress {
  content: "\f066"; }

%fa-icon-connectdevelop {
  content: "\f20e"; }

%fa-icon-copy {
  content: "\f0c5"; }

%fa-icon-copyright {
  content: "\f1f9"; }

%fa-icon-credit-card {
  content: "\f09d"; }

%fa-icon-crop {
  content: "\f125"; }

%fa-icon-crosshairs {
  content: "\f05b"; }

%fa-icon-css3 {
  content: "\f13c"; }

%fa-icon-cube {
  content: "\f1b2"; }

%fa-icon-cubes {
  content: "\f1b3"; }

%fa-icon-cut {
  content: "\f0c4"; }

%fa-icon-cutlery {
  content: "\f0f5"; }

%fa-icon-dashboard {
  content: "\f0e4"; }

%fa-icon-dashcube {
  content: "\f210"; }

%fa-icon-database {
  content: "\f1c0"; }

%fa-icon-dedent {
  content: "\f03b"; }

%fa-icon-delicious {
  content: "\f1a5"; }

%fa-icon-desktop {
  content: "\f108"; }

%fa-icon-deviantart {
  content: "\f1bd"; }

%fa-icon-diamond {
  content: "\f219"; }

%fa-icon-digg {
  content: "\f1a6"; }

%fa-icon-dollar {
  content: "\f155"; }

%fa-icon-dot-circle-o {
  content: "\f192"; }

%fa-icon-download {
  content: "\f019"; }

%fa-icon-dribbble {
  content: "\f17d"; }

%fa-icon-dropbox {
  content: "\f16b"; }

%fa-icon-drupal {
  content: "\f1a9"; }

%fa-icon-edit {
  content: "\f044"; }

%fa-icon-eject {
  content: "\f052"; }

%fa-icon-ellipsis-h {
  content: "\f141"; }

%fa-icon-ellipsis-v {
  content: "\f142"; }

%fa-icon-empire {
  content: "\f1d1"; }

%fa-icon-envelope {
  content: "\f0e0"; }

%fa-icon-envelope-o {
  content: "\f003"; }

%fa-icon-envelope-square {
  content: "\f199"; }

%fa-icon-eraser {
  content: "\f12d"; }

%fa-icon-eur {
  content: "\f153"; }

%fa-icon-euro {
  content: "\f153"; }

%fa-icon-exchange {
  content: "\f0ec"; }

%fa-icon-exclamation {
  content: "\f12a"; }

%fa-icon-exclamation-circle {
  content: "\f06a"; }

%fa-icon-exclamation-triangle {
  content: "\f071"; }

%fa-icon-expand {
  content: "\f065"; }

%fa-icon-external-link {
  content: "\f08e"; }

%fa-icon-external-link-square {
  content: "\f14c"; }

%fa-icon-eye {
  content: "\f06e"; }

%fa-icon-eye-slash {
  content: "\f070"; }

%fa-icon-eyedropper {
  content: "\f1fb"; }

%fa-icon-facebook {
  content: "\f09a"; }

%fa-icon-facebook-f {
  content: "\f09a"; }

%fa-icon-facebook-official {
  content: "\f230"; }

%fa-icon-facebook-square {
  content: "\f082"; }

%fa-icon-fast-backward {
  content: "\f049"; }

%fa-icon-fast-forward {
  content: "\f050"; }

%fa-icon-fax {
  content: "\f1ac"; }

%fa-icon-female {
  content: "\f182"; }

%fa-icon-fighter-jet {
  content: "\f0fb"; }

%fa-icon-file {
  content: "\f15b"; }

%fa-icon-file-archive-o {
  content: "\f1c6"; }

%fa-icon-file-audio-o {
  content: "\f1c7"; }

%fa-icon-file-code-o {
  content: "\f1c9"; }

%fa-icon-file-excel-o {
  content: "\f1c3"; }

%fa-icon-file-image-o {
  content: "\f1c5"; }

%fa-icon-file-movie-o {
  content: "\f1c8"; }

%fa-icon-file-o {
  content: "\f016"; }

%fa-icon-file-pdf-o {
  content: "\f1c1"; }

%fa-icon-file-photo-o {
  content: "\f1c5"; }

%fa-icon-file-picture-o {
  content: "\f1c5"; }

%fa-icon-file-powerpoint-o {
  content: "\f1c4"; }

%fa-icon-file-sound-o {
  content: "\f1c7"; }

%fa-icon-file-text {
  content: "\f15c"; }

%fa-icon-file-text-o {
  content: "\f0f6"; }

%fa-icon-file-video-o {
  content: "\f1c8"; }

%fa-icon-file-word-o {
  content: "\f1c2"; }

%fa-icon-file-zip-o {
  content: "\f1c6"; }

%fa-icon-files-o {
  content: "\f0c5"; }

%fa-icon-film {
  content: "\f008"; }

%fa-icon-filter {
  content: "\f0b0"; }

%fa-icon-fire {
  content: "\f06d"; }

%fa-icon-fire-extinguisher {
  content: "\f134"; }

%fa-icon-flag {
  content: "\f024"; }

%fa-icon-flag-checkered {
  content: "\f11e"; }

%fa-icon-flag-o {
  content: "\f11d"; }

%fa-icon-flash {
  content: "\f0e7"; }

%fa-icon-flask {
  content: "\f0c3"; }

%fa-icon-flickr {
  content: "\f16e"; }

%fa-icon-floppy-o {
  content: "\f0c7"; }

%fa-icon-folder {
  content: "\f07b"; }

%fa-icon-folder-o {
  content: "\f114"; }

%fa-icon-folder-open {
  content: "\f07c"; }

%fa-icon-folder-open-o {
  content: "\f115"; }

%fa-icon-font {
  content: "\f031"; }

%fa-icon-forumbee {
  content: "\f211"; }

%fa-icon-forward {
  content: "\f04e"; }

%fa-icon-foursquare {
  content: "\f180"; }

%fa-icon-frown-o {
  content: "\f119"; }

%fa-icon-futbol-o {
  content: "\f1e3"; }

%fa-icon-gamepad {
  content: "\f11b"; }

%fa-icon-gavel {
  content: "\f0e3"; }

%fa-icon-gbp {
  content: "\f154"; }

%fa-icon-ge {
  content: "\f1d1"; }

%fa-icon-gear {
  content: "\f013"; }

%fa-icon-gears {
  content: "\f085"; }

%fa-icon-genderless {
  content: "\f1db"; }

%fa-icon-gift {
  content: "\f06b"; }

%fa-icon-git {
  content: "\f1d3"; }

%fa-icon-git-square {
  content: "\f1d2"; }

%fa-icon-github {
  content: "\f09b"; }

%fa-icon-github-alt {
  content: "\f113"; }

%fa-icon-github-square {
  content: "\f092"; }

%fa-icon-gittip {
  content: "\f184"; }

%fa-icon-glass {
  content: "\f000"; }

%fa-icon-globe {
  content: "\f0ac"; }

%fa-icon-google {
  content: "\f1a0"; }

%fa-icon-google-plus {
  content: "\f0d5"; }

%fa-icon-google-plus-square {
  content: "\f0d4"; }

%fa-icon-google-wallet {
  content: "\f1ee"; }

%fa-icon-graduation-cap {
  content: "\f19d"; }

%fa-icon-gratipay {
  content: "\f184"; }

%fa-icon-group {
  content: "\f0c0"; }

%fa-icon-h-square {
  content: "\f0fd"; }

%fa-icon-hacker-news {
  content: "\f1d4"; }

%fa-icon-hand-o-down {
  content: "\f0a7"; }

%fa-icon-hand-o-left {
  content: "\f0a5"; }

%fa-icon-hand-o-right {
  content: "\f0a4"; }

%fa-icon-hand-o-up {
  content: "\f0a6"; }

%fa-icon-hdd-o {
  content: "\f0a0"; }

%fa-icon-header {
  content: "\f1dc"; }

%fa-icon-headphones {
  content: "\f025"; }

%fa-icon-heart {
  content: "\f004"; }

%fa-icon-heart-o {
  content: "\f08a"; }

%fa-icon-heartbeat {
  content: "\f21e"; }

%fa-icon-history {
  content: "\f1da"; }

%fa-icon-home {
  content: "\f015"; }

%fa-icon-hospital-o {
  content: "\f0f8"; }

%fa-icon-hotel {
  content: "\f236"; }

%fa-icon-html5 {
  content: "\f13b"; }

%fa-icon-ils {
  content: "\f20b"; }

%fa-icon-image {
  content: "\f03e"; }

%fa-icon-inbox {
  content: "\f01c"; }

%fa-icon-indent {
  content: "\f03c"; }

%fa-icon-info {
  content: "\f129"; }

%fa-icon-info-circle {
  content: "\f05a"; }

%fa-icon-inr {
  content: "\f156"; }

%fa-icon-instagram {
  content: "\f16d"; }

%fa-icon-institution {
  content: "\f19c"; }

%fa-icon-ioxhost {
  content: "\f208"; }

%fa-icon-italic {
  content: "\f033"; }

%fa-icon-joomla {
  content: "\f1aa"; }

%fa-icon-jpy {
  content: "\f157"; }

%fa-icon-jsfiddle {
  content: "\f1cc"; }

%fa-icon-key {
  content: "\f084"; }

%fa-icon-keyboard-o {
  content: "\f11c"; }

%fa-icon-krw {
  content: "\f159"; }

%fa-icon-language {
  content: "\f1ab"; }

%fa-icon-laptop {
  content: "\f109"; }

%fa-icon-lastfm {
  content: "\f202"; }

%fa-icon-lastfm-square {
  content: "\f203"; }

%fa-icon-leaf {
  content: "\f06c"; }

%fa-icon-leanpub {
  content: "\f212"; }

%fa-icon-legal {
  content: "\f0e3"; }

%fa-icon-lemon-o {
  content: "\f094"; }

%fa-icon-level-down {
  content: "\f149"; }

%fa-icon-level-up {
  content: "\f148"; }

%fa-icon-life-bouy {
  content: "\f1cd"; }

%fa-icon-life-buoy {
  content: "\f1cd"; }

%fa-icon-life-ring {
  content: "\f1cd"; }

%fa-icon-life-saver {
  content: "\f1cd"; }

%fa-icon-lightbulb-o {
  content: "\f0eb"; }

%fa-icon-line-chart {
  content: "\f201"; }

%fa-icon-link {
  content: "\f0c1"; }

%fa-icon-linkedin {
  content: "\f0e1"; }

%fa-icon-linkedin-square {
  content: "\f08c"; }

%fa-icon-linux {
  content: "\f17c"; }

%fa-icon-list {
  content: "\f03a"; }

%fa-icon-list-alt {
  content: "\f022"; }

%fa-icon-list-ol {
  content: "\f0cb"; }

%fa-icon-list-ul {
  content: "\f0ca"; }

%fa-icon-location-arrow {
  content: "\f124"; }

%fa-icon-lock {
  content: "\f023"; }

%fa-icon-long-arrow-down {
  content: "\f175"; }

%fa-icon-long-arrow-left {
  content: "\f177"; }

%fa-icon-long-arrow-right {
  content: "\f178"; }

%fa-icon-long-arrow-up {
  content: "\f176"; }

%fa-icon-magic {
  content: "\f0d0"; }

%fa-icon-magnet {
  content: "\f076"; }

%fa-icon-mail-forward {
  content: "\f064"; }

%fa-icon-mail-reply {
  content: "\f112"; }

%fa-icon-mail-reply-all {
  content: "\f122"; }

%fa-icon-male {
  content: "\f183"; }

%fa-icon-map-marker {
  content: "\f041"; }

%fa-icon-mars {
  content: "\f222"; }

%fa-icon-mars-double {
  content: "\f227"; }

%fa-icon-mars-stroke {
  content: "\f229"; }

%fa-icon-mars-stroke-h {
  content: "\f22b"; }

%fa-icon-mars-stroke-v {
  content: "\f22a"; }

%fa-icon-maxcdn {
  content: "\f136"; }

%fa-icon-meanpath {
  content: "\f20c"; }

%fa-icon-medium {
  content: "\f23a"; }

%fa-icon-medkit {
  content: "\f0fa"; }

%fa-icon-meh-o {
  content: "\f11a"; }

%fa-icon-mercury {
  content: "\f223"; }

%fa-icon-microphone {
  content: "\f130"; }

%fa-icon-microphone-slash {
  content: "\f131"; }

%fa-icon-minus {
  content: "\f068"; }

%fa-icon-minus-circle {
  content: "\f056"; }

%fa-icon-minus-square {
  content: "\f146"; }

%fa-icon-minus-square-o {
  content: "\f147"; }

%fa-icon-mobile {
  content: "\f10b"; }

%fa-icon-mobile-phone {
  content: "\f10b"; }

%fa-icon-money {
  content: "\f0d6"; }

%fa-icon-moon-o {
  content: "\f186"; }

%fa-icon-mortar-board {
  content: "\f19d"; }

%fa-icon-motorcycle {
  content: "\f21c"; }

%fa-icon-music {
  content: "\f001"; }

%fa-icon-navicon {
  content: "\f0c9"; }

%fa-icon-neuter {
  content: "\f22c"; }

%fa-icon-newspaper-o {
  content: "\f1ea"; }

%fa-icon-openid {
  content: "\f19b"; }

%fa-icon-outdent {
  content: "\f03b"; }

%fa-icon-pagelines {
  content: "\f18c"; }

%fa-icon-paint-brush {
  content: "\f1fc"; }

%fa-icon-paper-plane {
  content: "\f1d8"; }

%fa-icon-paper-plane-o {
  content: "\f1d9"; }

%fa-icon-paperclip {
  content: "\f0c6"; }

%fa-icon-paragraph {
  content: "\f1dd"; }

%fa-icon-paste {
  content: "\f0ea"; }

%fa-icon-pause {
  content: "\f04c"; }

%fa-icon-paw {
  content: "\f1b0"; }

%fa-icon-paypal {
  content: "\f1ed"; }

%fa-icon-pencil {
  content: "\f040"; }

%fa-icon-pencil-square {
  content: "\f14b"; }

%fa-icon-pencil-square-o {
  content: "\f044"; }

%fa-icon-phone {
  content: "\f095"; }

%fa-icon-phone-square {
  content: "\f098"; }

%fa-icon-photo {
  content: "\f03e"; }

%fa-icon-picture-o {
  content: "\f03e"; }

%fa-icon-pie-chart {
  content: "\f200"; }

%fa-icon-pied-piper {
  content: "\f1a7"; }

%fa-icon-pied-piper-alt {
  content: "\f1a8"; }

%fa-icon-pinterest {
  content: "\f0d2"; }

%fa-icon-pinterest-p {
  content: "\f231"; }

%fa-icon-pinterest-square {
  content: "\f0d3"; }

%fa-icon-plane {
  content: "\f072"; }

%fa-icon-play {
  content: "\f04b"; }

%fa-icon-play-circle {
  content: "\f144"; }

%fa-icon-play-circle-o {
  content: "\f01d"; }

%fa-icon-plug {
  content: "\f1e6"; }

%fa-icon-plus {
  content: "\f067"; }

%fa-icon-plus-circle {
  content: "\f055"; }

%fa-icon-plus-square {
  content: "\f0fe"; }

%fa-icon-plus-square-o {
  content: "\f196"; }

%fa-icon-power-off {
  content: "\f011"; }

%fa-icon-print {
  content: "\f02f"; }

%fa-icon-puzzle-piece {
  content: "\f12e"; }

%fa-icon-qq {
  content: "\f1d6"; }

%fa-icon-qrcode {
  content: "\f029"; }

%fa-icon-question {
  content: "\f128"; }

%fa-icon-question-circle {
  content: "\f059"; }

%fa-icon-quote-left {
  content: "\f10d"; }

%fa-icon-quote-right {
  content: "\f10e"; }

%fa-icon-ra {
  content: "\f1d0"; }

%fa-icon-random {
  content: "\f074"; }

%fa-icon-rebel {
  content: "\f1d0"; }

%fa-icon-recycle {
  content: "\f1b8"; }

%fa-icon-reddit {
  content: "\f1a1"; }

%fa-icon-reddit-square {
  content: "\f1a2"; }

%fa-icon-refresh {
  content: "\f021"; }

%fa-icon-remove {
  content: "\f00d"; }

%fa-icon-renren {
  content: "\f18b"; }

%fa-icon-reorder {
  content: "\f0c9"; }

%fa-icon-repeat {
  content: "\f01e"; }

%fa-icon-reply {
  content: "\f112"; }

%fa-icon-reply-all {
  content: "\f122"; }

%fa-icon-retweet {
  content: "\f079"; }

%fa-icon-rmb {
  content: "\f157"; }

%fa-icon-road {
  content: "\f018"; }

%fa-icon-rocket {
  content: "\f135"; }

%fa-icon-rotate-left {
  content: "\f0e2"; }

%fa-icon-rotate-right {
  content: "\f01e"; }

%fa-icon-rouble {
  content: "\f158"; }

%fa-icon-rss {
  content: "\f09e"; }

%fa-icon-rss-square {
  content: "\f143"; }

%fa-icon-rub {
  content: "\f158"; }

%fa-icon-ruble {
  content: "\f158"; }

%fa-icon-rupee {
  content: "\f156"; }

%fa-icon-save {
  content: "\f0c7"; }

%fa-icon-scissors {
  content: "\f0c4"; }

%fa-icon-search {
  content: "\f002"; }

%fa-icon-search-minus {
  content: "\f010"; }

%fa-icon-search-plus {
  content: "\f00e"; }

%fa-icon-sellsy {
  content: "\f213"; }

%fa-icon-send {
  content: "\f1d8"; }

%fa-icon-send-o {
  content: "\f1d9"; }

%fa-icon-server {
  content: "\f233"; }

%fa-icon-share {
  content: "\f064"; }

%fa-icon-share-alt {
  content: "\f1e0"; }

%fa-icon-share-alt-square {
  content: "\f1e1"; }

%fa-icon-share-square {
  content: "\f14d"; }

%fa-icon-share-square-o {
  content: "\f045"; }

%fa-icon-shekel {
  content: "\f20b"; }

%fa-icon-sheqel {
  content: "\f20b"; }

%fa-icon-shield {
  content: "\f132"; }

%fa-icon-ship {
  content: "\f21a"; }

%fa-icon-shirtsinbulk {
  content: "\f214"; }

%fa-icon-shopping-cart {
  content: "\f07a"; }

%fa-icon-sign-in {
  content: "\f090"; }

%fa-icon-sign-out {
  content: "\f08b"; }

%fa-icon-signal {
  content: "\f012"; }

%fa-icon-simplybuilt {
  content: "\f215"; }

%fa-icon-sitemap {
  content: "\f0e8"; }

%fa-icon-skyatlas {
  content: "\f216"; }

%fa-icon-skype {
  content: "\f17e"; }

%fa-icon-slack {
  content: "\f198"; }

%fa-icon-sliders {
  content: "\f1de"; }

%fa-icon-slideshare {
  content: "\f1e7"; }

%fa-icon-smile-o {
  content: "\f118"; }

%fa-icon-soccer-ball-o {
  content: "\f1e3"; }

%fa-icon-sort {
  content: "\f0dc"; }

%fa-icon-sort-alpha-asc {
  content: "\f15d"; }

%fa-icon-sort-alpha-desc {
  content: "\f15e"; }

%fa-icon-sort-amount-asc {
  content: "\f160"; }

%fa-icon-sort-amount-desc {
  content: "\f161"; }

%fa-icon-sort-asc {
  content: "\f0de"; }

%fa-icon-sort-desc {
  content: "\f0dd"; }

%fa-icon-sort-down {
  content: "\f0dd"; }

%fa-icon-sort-numeric-asc {
  content: "\f162"; }

%fa-icon-sort-numeric-desc {
  content: "\f163"; }

%fa-icon-sort-up {
  content: "\f0de"; }

%fa-icon-soundcloud {
  content: "\f1be"; }

%fa-icon-space-shuttle {
  content: "\f197"; }

%fa-icon-spinner {
  content: "\f110"; }

%fa-icon-spoon {
  content: "\f1b1"; }

%fa-icon-spotify {
  content: "\f1bc"; }

%fa-icon-square {
  content: "\f0c8"; }

%fa-icon-square-o {
  content: "\f096"; }

%fa-icon-stack-exchange {
  content: "\f18d"; }

%fa-icon-stack-overflow {
  content: "\f16c"; }

%fa-icon-star {
  content: "\f005"; }

%fa-icon-star-half {
  content: "\f089"; }

%fa-icon-star-half-empty {
  content: "\f123"; }

%fa-icon-star-half-full {
  content: "\f123"; }

%fa-icon-star-half-o {
  content: "\f123"; }

%fa-icon-star-o {
  content: "\f006"; }

%fa-icon-steam {
  content: "\f1b6"; }

%fa-icon-steam-square {
  content: "\f1b7"; }

%fa-icon-step-backward {
  content: "\f048"; }

%fa-icon-step-forward {
  content: "\f051"; }

%fa-icon-stethoscope {
  content: "\f0f1"; }

%fa-icon-stop {
  content: "\f04d"; }

%fa-icon-street-view {
  content: "\f21d"; }

%fa-icon-strikethrough {
  content: "\f0cc"; }

%fa-icon-stumbleupon {
  content: "\f1a4"; }

%fa-icon-stumbleupon-circle {
  content: "\f1a3"; }

%fa-icon-subscript {
  content: "\f12c"; }

%fa-icon-subway {
  content: "\f239"; }

%fa-icon-suitcase {
  content: "\f0f2"; }

%fa-icon-sun-o {
  content: "\f185"; }

%fa-icon-superscript {
  content: "\f12b"; }

%fa-icon-support {
  content: "\f1cd"; }

%fa-icon-table {
  content: "\f0ce"; }

%fa-icon-tablet {
  content: "\f10a"; }

%fa-icon-tachometer {
  content: "\f0e4"; }

%fa-icon-tag {
  content: "\f02b"; }

%fa-icon-tags {
  content: "\f02c"; }

%fa-icon-tasks {
  content: "\f0ae"; }

%fa-icon-taxi {
  content: "\f1ba"; }

%fa-icon-tencent-weibo {
  content: "\f1d5"; }

%fa-icon-terminal {
  content: "\f120"; }

%fa-icon-text-height {
  content: "\f034"; }

%fa-icon-text-width {
  content: "\f035"; }

%fa-icon-th {
  content: "\f00a"; }

%fa-icon-th-large {
  content: "\f009"; }

%fa-icon-th-list {
  content: "\f00b"; }

%fa-icon-thumb-tack {
  content: "\f08d"; }

%fa-icon-thumbs-down {
  content: "\f165"; }

%fa-icon-thumbs-o-down {
  content: "\f088"; }

%fa-icon-thumbs-o-up {
  content: "\f087"; }

%fa-icon-thumbs-up {
  content: "\f164"; }

%fa-icon-ticket {
  content: "\f145"; }

%fa-icon-times {
  content: "\f00d"; }

%fa-icon-times-circle {
  content: "\f057"; }

%fa-icon-times-circle-o {
  content: "\f05c"; }

%fa-icon-tint {
  content: "\f043"; }

%fa-icon-toggle-down {
  content: "\f150"; }

%fa-icon-toggle-left {
  content: "\f191"; }

%fa-icon-toggle-off {
  content: "\f204"; }

%fa-icon-toggle-on {
  content: "\f205"; }

%fa-icon-toggle-right {
  content: "\f152"; }

%fa-icon-toggle-up {
  content: "\f151"; }

%fa-icon-train {
  content: "\f238"; }

%fa-icon-transgender {
  content: "\f224"; }

%fa-icon-transgender-alt {
  content: "\f225"; }

%fa-icon-trash {
  content: "\f1f8"; }

%fa-icon-trash-o {
  content: "\f014"; }

%fa-icon-tree {
  content: "\f1bb"; }

%fa-icon-trello {
  content: "\f181"; }

%fa-icon-trophy {
  content: "\f091"; }

%fa-icon-truck {
  content: "\f0d1"; }

%fa-icon-try {
  content: "\f195"; }

%fa-icon-tty {
  content: "\f1e4"; }

%fa-icon-tumblr {
  content: "\f173"; }

%fa-icon-tumblr-square {
  content: "\f174"; }

%fa-icon-turkish-lira {
  content: "\f195"; }

%fa-icon-twitch {
  content: "\f1e8"; }

%fa-icon-twitter {
  content: "\f099"; }

%fa-icon-twitter-square {
  content: "\f081"; }

%fa-icon-umbrella {
  content: "\f0e9"; }

%fa-icon-underline {
  content: "\f0cd"; }

%fa-icon-undo {
  content: "\f0e2"; }

%fa-icon-university {
  content: "\f19c"; }

%fa-icon-unlink {
  content: "\f127"; }

%fa-icon-unlock {
  content: "\f09c"; }

%fa-icon-unlock-alt {
  content: "\f13e"; }

%fa-icon-unsorted {
  content: "\f0dc"; }

%fa-icon-upload {
  content: "\f093"; }

%fa-icon-usd {
  content: "\f155"; }

%fa-icon-user {
  content: "\f007"; }

%fa-icon-user-md {
  content: "\f0f0"; }

%fa-icon-user-plus {
  content: "\f234"; }

%fa-icon-user-secret {
  content: "\f21b"; }

%fa-icon-user-times {
  content: "\f235"; }

%fa-icon-users {
  content: "\f0c0"; }

%fa-icon-venus {
  content: "\f221"; }

%fa-icon-venus-double {
  content: "\f226"; }

%fa-icon-venus-mars {
  content: "\f228"; }

%fa-icon-viacoin {
  content: "\f237"; }

%fa-icon-video-camera {
  content: "\f03d"; }

%fa-icon-vimeo-square {
  content: "\f194"; }

%fa-icon-vine {
  content: "\f1ca"; }

%fa-icon-vk {
  content: "\f189"; }

%fa-icon-volume-down {
  content: "\f027"; }

%fa-icon-volume-off {
  content: "\f026"; }

%fa-icon-volume-up {
  content: "\f028"; }

%fa-icon-warning {
  content: "\f071"; }

%fa-icon-wechat {
  content: "\f1d7"; }

%fa-icon-weibo {
  content: "\f18a"; }

%fa-icon-weixin {
  content: "\f1d7"; }

%fa-icon-whatsapp {
  content: "\f232"; }

%fa-icon-wheelchair {
  content: "\f193"; }

%fa-icon-wifi {
  content: "\f1eb"; }

%fa-icon-windows {
  content: "\f17a"; }

%fa-icon-won {
  content: "\f159"; }

%fa-icon-wordpress {
  content: "\f19a"; }

%fa-icon-wrench {
  content: "\f0ad"; }

%fa-icon-xing {
  content: "\f168"; }

%fa-icon-xing-square {
  content: "\f169"; }

%fa-icon-yahoo {
  content: "\f19e"; }

%fa-icon-yelp {
  content: "\f1e9"; }

%fa-icon-yen {
  content: "\f157"; }

%fa-icon-youtube {
  content: "\f167"; }

%fa-icon-youtube-play {
  content: "\f16a"; }

%fa-icon-youtube-square {
  content: "\f166"; }
