/* generelle Variablen */
//Fonts
$font-roboto: 'Roboto', sans-serif;
$font-roboto-condensed: 'Roboto Light', sans-serif;
$font-gilroy-black: 'gilroy-black', sans-serif;
$font-gilroy-bold: 'gilroy-bold', sans-serif;

// main colors
$primary-color: #5e5e5e;
$secondary-color: #ffb011;
$standard-text-color: #333333;
$light-text-color: #808080;
$link-color: #003d6a;

//## Gray color hierarchie
$color-gray-base: #000000;
$color-gray1: lighten($color-gray-base, 6.7%);
; //#111111
$color-gray2: lighten($color-gray-base, 13.5%); // #222
$color-gray3: lighten($color-gray-base, 20%); // #333
$color-gray4: lighten($color-gray-base, 20%); // #444
$color-gray5: lighten($color-gray-base, 33.5%); // #555
$color-gray6: lighten($color-gray-base, 40%); // #666
$color-gray7: lighten($color-gray-base, 46.7%); // #777
$color-gray8: lighten($color-gray-base, 53.3%); // #888
$color-gray9: lighten($color-gray-base, 53.3%); // #999
$color-gray10: lighten($color-gray-base, 66.7%); // #aaa
$color-gray11: lighten($color-gray-base, 73.3%); // #bbb
$color-gray12: lighten($color-gray-base, 80%); // #ccc
$color-gray13: lighten($color-gray-base, 86.7%); // #ddd
$color-gray14: lighten($color-gray-base, 93.5%); // #eee


// Farben von PYUR

$pystachio-color: #9AD21C;
$pynk-color: #de0c47;
$azyr-color: #0FCBEA;
$grey-color: #999999;
$lyghtgrey-color: #e5e5e5;

$pystachio-text: #060606;
$pynk-text: #F7F7F7;
$azyr-text: #0A0A0A;
$grey-text: #060606;
$lyghtgrey-text: #060606;


//Main colors

$theme-black: $color-gray-base;
$theme-black-dark: #0c0b3e;
$theme-white: #ffffff;
$theme-red: #de0c47;
$theme-blue: #0fcbea;
$theme-green: #9ad21c;
$theme-green-dark: #0f822c;
$theme-gray-light: #f0f0f0;
$theme-gray-lighter: #fafafa;
//button gereralized
$btn-background-color: $theme-white;
$btn-font-color: $theme-black;
$color-danger:#a94442;

//heading color
$heading-color: $theme-black;

//General border color
$border-color: $theme-black;
$border-color-green: $theme-green;

//Data Table Colors:

// Bootstrap Elemente
// alert
$bs-primary-bg: $theme-white;
$bs-primary-color: $theme-black;
$bs-default-bg: $bs-primary-bg;
$bs-default-color: $bs-primary-color;
$bs-success-color: $pystachio-text;
$bs-success-bg: $pystachio-color;
$bs-info-color: $azyr-text;
$bs-info-bg: $azyr-color;
$bs-warning-color: $lyghtgrey-text;
$bs-warning-bg: $lyghtgrey-color;
$bs-danger-color: $pynk-text;
$bs-danger-bg: $pynk-color;
$alert-border-radius: 25px;

// panels
$panel-heading-border-radius: 25px;
$panel-heading-padding: 15px;

// Links
$link-text-color: $theme-black;
$link-hover-color: $pystachio-color;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//Mobile view font-size
$mobile-headings: 13pt;

$placeholder-color: $color-gray9; //#bbbbbb;
$placeholder-size: 12pt;

$body-font-stack: $font-roboto;
$body-font-size: 1em;
$body-font-weight: 400;
$body-font-color: $theme-black;
$body-line-height: 1.5;
$body-background-color: $theme-gray-light;
$body-background-image: url(../images/background.jpg);
$body-h1-font-size: 26pt;
$body-h2-font-size: 18pt;
$body-h3-font-size: 14pt;
$body-h4-font-size: 12pt;
$body-h5-font-size: 10pt;
$body-h6-font-size: 10pt;

$body-h1-mobile-font-size: 20pt;
$body-h2-mobile-font-size: 16pt;
$body-h3-mobile-font-size: 14pt;
$body-h4-mobile-font-size: 12pt;
$body-h5-mobile-font-size: 10pt;
$body-h6-mobile-font-size: 10pt;

$body-h1-font-weight: 500;
$body-h2-font-weight: 500;
$body-h3-font-weight: 500;
$body-h4-font-weight: 500;
$body-h5-font-weight: 700;
$body-h6-font-weight: 700;
$body-h1-margin: 20px 0 30px 0;
$body-h1-margin-mobile: 10px 0 10px 0;
$body-h2-margin: 20px 0 20px 0;
$body-h3-margin: 10px 0 10px 0;
$body-h4-margin: 10px 0 10px 0;
$body-h5-margin: 20px 0 5px 0;
$body-h6-margin: 0 0 10px 0;
// button
$body-btn-success-background: $btn-background-color;
$body-btn-success-border: 1px solid transparent;
$body-btn-success-font-color: $btn-font-color;

// help block
$body-helpblock-font-color: $theme-black;
$body-helpblock-icon-font-color: $theme-black;
$body-helpblock-font-size: 10pt;
$body-helpblock-icon-font-size: 10pt;

// alerts
$body-alert-warning-background: $theme-white;
$body-alert-warning-border: 1px solid $theme-black;
$body-alert-warning-font-color: $theme-black;

// - fontawesome Icons
$body-icon-font-size: 14pt;
$body-icon-font-color: $theme-black;
// - links
$body-link-font-size: 1em;
$body-link-font-color: $theme-black;
$body-link-font-color-hover: $theme-black-dark;
// - highlight
$body-highlight-font-color: $theme-white;
$body-highlight-background-color: $theme-green;

/* header Region */
$header-background-color: $theme-white;
$header-gradient-to-background-color: $theme-white;
$header-gradient-from-background-color: $theme-white;
$header-background-image: none;

// - header-menu
// -- slogan
$header-slogan-font-color: $theme-black;
// -- fa icons
$header-menu-font-size: 10pt;
$header-menu-font-color: $theme-black;
$header-menu-badge-font-color: $theme-white;
$header-menu-badge-background-color: $theme-red;
$header-menu-badge-font-size: 10pt;
// --- dropdown menu bei Hovern des Icons
$header-menu-dropdown-menu-font-size: 10pt;
$header-menu-dropdown-menu-font-color: $theme-black;
$header-menu-dropdown-menu-background-color: $theme-white;
// -- dropdown menu link
$header-menu-dropdown-menu-link-font-size: 10pt;
$header-menu-dropdown-menu-link-font-color: $theme-black;
$header-menu-dropdown-menu-link-font-color-hover: $theme-white;
$header-menu-dropdown-menu-link-background-color-hover: $theme-green;
// --- dropdown menu link fa icon
$header-menu-dropdown-menu-link-icon-font-size: 18pt;
$header-menu-dropdown-menu-li-link-icon-font-size: 1.3em;
$header-menu-dropdown-menu-link-icon-font-color: $theme-black;
$header-menu-dropdown-menu-li-link-icon-font-color: $theme-black;
$header-menu-dropdown-menu-link-icon-font-color-hover: $theme-white;
// -- dropdown menu "all" link e.g. "Alle Benachrichtigungen"
$header-menu-dropdown-menu-all-link-font-size: 10pt;
$header-menu-dropdown-menu-all-link-font-color: $theme-black;
$header-menu-dropdown-menu-all-link-font-color-hover: $theme-white;
$header-menu-dropdown-menu-all-link-background-color: $theme-white;
$header-menu-dropdown-menu-all-link-background-color-hover: $theme-green;

// - header Suche-Formular
$header-search-font-size: 12pt;
$header-search-font-color: $theme-black;
$header-search-background-color: $theme-white;
$header-search-border: 1px solid $theme-gray-light;
$header-search-placeholder-font-color: $theme-black;
$header-search-placeholder-font-size: 12pt;
$header-search-icon-font-color: $theme-black;
$header-search-icon-font-size: 14pt;

// - header News-ticker
$header-newsticker-height: 23px;
$header-newsticker-font-size: 15pt;
$header-newsticker-font-color: $theme-white;
$header-newsticker-link-font-color: $theme-white;
$header-newsticker-background-color: $theme-red;
$header-newsticker-link-font-size: 15pt;

/* content Region */
// - main menu
$content-menu-background-color: transparent;
//$content-menu-background-color: transparent;
// -- headline
$content-menu-topheadline-font-size: 15pt;
$content-menu-topheadline-font-color: $theme-black;
$content-menu-topheadline-background-color: $theme-black;
$content-menu-topheadline-icon-font-color: $theme-white;
$content-menu-topheadline-icon-font-size: 15pt;
// -- headline
$content-menu-headline-font-size: 15pt;
$content-menu-headline-font-color: $theme-black;
$content-menu-headline-background-color: $theme-white;
$content-menu-headline-icon-font-color: $theme-black;
$content-menu-headline-icon-font-size: 15pt;
// -- link
$content-menu-link-font-size: 12pt;
$content-menu-link-font-color: $theme-black;
$content-menu-link-border-top: 1px solid $border-color;
$content-menu-link-font-color-hover: $theme-white;
$content-menu-link-background-color: $theme-white;
$content-menu-link-background-color-hover: $theme-green;
$content-menu-link-icon-font-color: $theme-black;
$content-menu-link-icon-font-color-hover: $theme-white;
$content-menu-link-icon-font-size: 12pt;
// -- link of current page
$content-menu-current-link-font-size: 12pt;
$content-menu-current-link-font-color: $theme-black;
$content-menu-current-link-font-color-hover: $theme-white;
$content-menu-current-link-background-color: $theme-black;
$content-menu-current-link-background-color-hover: transparent;
$content-menu-current-link-icon-font-color: $theme-black;
$content-menu-current-link-icon-font-color-hover: 12pt;
$content-menu-current-link-icon-font-size: 15pt;

// - content wrapper
$content-wrapper-font-size: 10pt; //12pt;
$content-wrapper-font-color: $theme-black;
$content-wrapper-background-color: $theme-white;
// -- header row
$content-wrapper-title-background-color: transparent;
$content-wrapper-title-font-color: $theme-black;
$content-wrapper-title-font-size: 21pt;
// -- header row
$content-wrapper-header-font-size: 18pt;
$content-wrapper-header-font-color: $theme-black;
$content-wrapper-header-background-color: $theme-white;
$content-wrapper-header-icon-font-size: 15pt;
$content-wrapper-header-icon-font-color: $theme-black;
$content-wrapper-header-icon-background-color: $theme-white;
$content-wrapper-header-border-bottom-color: $border-color;
// --- breadcrumbs
$content-wrapper-breadcrumbs-background-color: $theme-white;
// ---- step (Zwischenschritt)
$content-wrapper-breadcrumbs-font-size: 12pt;
$content-wrapper-breadcrumbs-font-color: $theme-black;
$content-wrapper-breadcrumbs-icon-font-size: 12pt;
$content-wrapper-breadcrumbs-icon-font-color: $theme-black;
// ---- current step (Letztes Element im Breadcrumb (zB aktueller Seitenname))
$content-wrapper-breadcrumbs-current-font-size: 12pt;
$content-wrapper-breadcrumbs-current-font-color: $theme-black;
// --- content menu
$content-wrapper-menu-font-size: 12pt;
$content-wrapper-menu-font-color: $theme-black;
$content-wrapper-menu-icon-font-size: 15pt;
$content-wrapper-menu-icon-font-color: $theme-black;
$content-wrapper-menu-icon-font-color-hover: $theme-black;
$content-wrapper-menu-icon-background-color: $theme-white;
$content-wrapper-menu-icon-background-color-hover: $theme-white;
// ---- link
$content-wrapper-menu-link-font-size: 12pt;
$content-wrapper-menu-link-font-color: $color-gray7;
$content-wrapper-menu-link-font-color-hover: $theme-black;
$content-wrapper-menu-link-background-color: $theme-white;
$content-wrapper-menu-link-background-color-hover: $theme-white;
$content-wrapper-menu-link-icon-font-color: $theme-black;
$content-wrapper-menu-link-icon-font-color-hover: $theme-black;
$content-wrapper-menu-link-icon-font-size: 12pt;
// ---- editor link
$content-wrapper-menu-editor-link-font-size: 12pt;
$content-wrapper-menu-editor-link-font-color: $theme-black;
$content-wrapper-menu-editor-link-font-color-hover: $theme-black;
$content-wrapper-menu-editor-link-background-color: $theme-white;
$content-wrapper-menu-editor-link-background-color-hover: $theme-white;
$content-wrapper-menu-editor-link-icon-font-color: $theme-black;
$content-wrapper-menu-editor-link-icon-font-color-hover: $theme-black;
$content-wrapper-menu-editor-link-icon-font-size: 12pt;
// -- table
$content-wrapper-table-font-size: 10pt;
$content-wrapper-table-font-color: $theme-black;
$content-wrapper-table-border: none;
$content-wrapper-table-background: trasparent;
// --- head
$content-wrapper-table-head-font-size: 10pt;
$content-wrapper-table-head-font-color: $theme-black;
$content-wrapper-table-head-border: 0px dotted $theme-black;
$content-wrapper-table-head-border-bottom: 2px solid $color-gray8;
$content-wrapper-table-head-background: transparent;
// --- filter
$content-wrapper-table-filter-background: $theme-white;
// --- row odd
$content-wrapper-table-row-odd-font-size: 10pt;
$content-wrapper-table-row-odd-font-color: $theme-black;
$content-wrapper-table-row-odd-border: 0px dotted $theme-black;
$content-wrapper-table-row-odd-border-bottom: 2px solid $color-gray11;
$content-wrapper-table-row-odd-background: transparent;
// --- row even
$content-wrapper-table-row-even-font-size: 10pt;
$content-wrapper-table-row-even-font-color: $theme-black;
$content-wrapper-table-row-even-border: 0px dotted $theme-black;
$content-wrapper-table-row-even-border-bottom: 2px solid $color-gray11;
$content-wrapper-table-row-even-background: transparent;
// --- footer
$content-wrapper-table-foot-font-size: 10pt;
$content-wrapper-table-foot-font-color: $theme-white;
$content-wrapper-table-foot-border: 0px solid $theme-white;
$content-wrapper-table-foot-border-bottom: none;
$content-wrapper-table-foot-background: transparent;

// - TABS
$content-wrapper-tabs-head-link-color: $theme-black;
$content-wrapper-tabs-head-link-background: $theme-white;
$content-wrapper-tabs-head-link-font-size: 12pt;
$content-wrapper-tabs-head-link-active-color: $theme-red;
$content-wrapper-tabs-head-link-active-background: $theme-white;
$content-wrapper-tabs-head-link-active-font-size: 12pt;
$content-wrapper-tabs-content-color: $theme-black;
$content-wrapper-tabs-content-background: $theme-white;
$content-wrapper-tabs-content-font-size: 12pt;

// - ACCORDION
$content-wrapper-accordion-head-color: $theme-black;
$content-wrapper-accordion-head-background: $theme-white;
$content-wrapper-accordion-head-font-size: 12pt;
$content-wrapper-accordion-head-active-color: $theme-white;
$content-wrapper-accordion-head-active-background: $theme-white;
$content-wrapper-accordion-head-active-font-size: 12pt;
$content-wrapper-accordion-content-color: $theme-black;
$content-wrapper-accordion-content-background: $theme-white;
$content-wrapper-accordion-content-font-size: 12pt;

// - error wrapper
$content-error-font-size: 12pt;
$content-error-font-color: $theme-black;
$content-error-background-color: $theme-white;
// -- header row
$content-error-title-background-color: transparent;
$content-error-title-font-color: $theme-black;
$content-error-title-font-size: 15pt;
// -- header row
$content-error-header-font-size: 15pt;
$content-error-header-font-color: $theme-black;
$content-error-header-background-color: $theme-white;
$content-error-header-icon-font-size: 15pt;
$content-error-header-icon-font-color: $theme-black;
$content-error-header-icon-background-color: $theme-white;
$content-error-header-border-bottom-color: $theme-green;

// - right sidebar
$content-sidebar-background-color: $theme-white;
// -- headline
$content-sidebar-headline-font-size: 15pt;
$content-sidebar-headline-font-color: $heading-color;
$content-sidebar-headline-background-color: $theme-white;
$content-sidebar-headline-border-bottom: 1px solid $border-color;
$content-sidebar-headline-icon-font-color: $theme-white;
$content-sidebar-headline-icon-font-size: 15pt;
$content-sidebar-headline-icon-background-color: $theme-white;
// -- rubric
$content-sidebar-rubric-font-size: 10pt;
$content-sidebar-rubric-font-color: $theme-black;
// -- item
$content-sidebar-item-border-bottom: 1px dotted $border-color;
$content-sidebar-item-date-font-size: 10pt;
$content-sidebar-item-date-font-color: $theme-black;
$content-sidebar-item-title-font-size: 13pt;
$content-sidebar-item-title-font-color: $theme-black;
$content-sidebar-item-teaser-font-size: 11pt;
$content-sidebar-item-teaser-font-color: $theme-black;
$content-sidebar-item-link-font-size: 12pt;
$content-sidebar-item-link-font-color: $theme-black;
$content-sidebar-item-link-font-color-hover: $theme-black;
// -- footer
$content-sidebar-footer-background-color: $btn-background-color;
$content-sidebar-footer-font-size: 12pt;
$content-sidebar-footer-font-color: $btn-font-color;
$content-sidebar-footer-font-color-hover: $theme-black;

/* Front page */
// - widget
// -- head
$front-widget-head-font-color: $heading-color;
$front-widget-head-font-size: 15pt;
$front-widget-head-border-bottom: 1px solid $border-color;
$front-widget-head-background-color: $theme-white;
$front-widget-head-icon-font-size: 15pt;
$front-widget-head-icon-font-color: $theme-black;
$front-widget-head-icon-background-color: $theme-white;
// -- content
$front-widget-content-font-color: $theme-black;
$front-widget-content-date-font-size: 10pt;
$front-widget-content-date-font-color: $color-gray8;
// -- footer
$front-widget-footer-font-color: $btn-font-color;
$front-widget-footer-font-color-hover: $theme-white;
$front-widget-footer-font-size: 12pt;
$front-widget-footer-background-color: $btn-background-color;

// - widget special
// -- head
$front-widget-special-head-font-color: $theme-red;
$front-widget-special-head-font-size: 15pt;
$front-widget-special-head-border-bottom: 1px solid $border-color;
$front-widget-special-head-background-color: $theme-white;
$front-widget-special-head-icon-font-size: 15pt;
$front-widget-special-head-icon-font-color: $theme-red;
$front-widget-special-head-icon-background-color: $theme-white;
$front-widget-link-special-background-color: $theme-white;
$front-widget-link-background-color: $theme-white;
// -- content
$front-widget-special-content-font-color: $theme-black;
$front-widget-special-content-date-font-size: 10pt;
$front-widget-special-content-date-font-color: $color-gray8;
// -- footer
$front-widget-special-footer-font-color: $theme-black;
$front-widget-special-footer-font-color-hover: $theme-black;
$front-widget-special-footer-font-size: 12pt;
$front-widget-special-footer-background-color: $theme-white;

// - notice widget
$front-widget-notice-background-color: $theme-white;

// - link widget
$front-widget-text-icon-font-size: 35pt;
$front-widget-text-icon-font-color: $theme-white;

/* pager element */
$pager-link-font-size: 12pt;
$pager-link-font-color: $theme-black;
$pager-link-font-color-hover: $theme-black;
$pager-link-border: 1px solid $color-gray9;
$pager-link-border-hover: 1px solid $color-gray9;
$pager-link-background-color: $theme-white;
$pager-link-background-color-hover: $theme-white;
//  the active pager element border & background color
$pager-link-background-color-hover-active: $theme-white;
$pager-link-background-border-color-hover-active: 1px solid $theme-green;
// non active pager elements border & background color
$pager-link-background-color-border-hover-non-active: 1px solid $color-gray9;
$pager-link-background-color-hover-non-active: $theme-white;

// -- current link
$pager-current-link-font-size: 12pt;
$pager-current-link-font-color: $theme-black;
$pager-current-link-font-color-hover: $theme-black;
$pager-current-link-border: 1px solid $theme-green;
$pager-current-link-border-hover: 1px solid $theme-green;
$pager-current-link-background-color: $theme-white;
$pager-current-link-background-color-hover: $theme-white;
// -- last / first link
$pager-special-link-font-size: 12pt;
$pager-special-link-font-color: $theme-black;
$pager-special-link-font-color-hover: $theme-black;
$pager-special-link-border: 1px solid $color-gray9;
$pager-special-link-border-hover: 1px solid $color-gray9;
$pager-special-link-background-color: $theme-white;
$pager-special-link-background-color-hover: $theme-white;

/* news page */
// - item
$news-item-title-font-color: $theme-black;
$news-item-title-font-size: 15pt;
$news-item-teaser-font-color: $theme-black;
$news-item-teaser-font-size: 12pt;
$news-item-date-font-color: $theme-black;
$news-item-date-font-size: 12pt;
$news-item-link-font-size: 12pt;
$news-item-link-font-color: $theme-black;
$news-item-link-font-color-hover: $theme-black;

/* search */
// - live search
$search-header-live-background-color: $theme-white;
$search-header-live-border: 1px solid $theme-black;
$search-header-live-font-color: $theme-black;
$search-header-live-font-color-hover: $theme-black;
$search-header-live-font-size: 12pt;
$search-header-live-more-font-color: $theme-black;
$search-header-live-more-font-color-hover: $theme-black;
$search-header-live-more-font-size: 12pt;
// - search result
$search-header-tab-background-color: $theme-white;
$search-header-tab-background-color-hover: $theme-white;
$search-header-tab-font-color: $theme-black;
$search-header-tab-font-color-hover: $theme-black;
$search-header-tab-font-size: 15pt;
$search-header-tab-active-background-color: $theme-green;
$search-header-tab-active-background-color-hover: $theme-green;
$search-header-tab-active-font-color: $theme-black;
$search-header-tab-active-font-color-hover: $theme-black;
$search-header-tab-active-font-size: 15pt;

/* search for page */
// - item
$search-page-item-border: 1px dotted $color-gray9;
$search-page-item-background-color: $theme-white;
$search-page-item-title-font-color: $theme-black;
$search-page-item-title-font-size: 15pt;
$search-page-item-date-font-color: $theme-black;
$search-page-item-date-font-size: 12pt;
$search-page-item-teaser-font-color: $theme-black;
$search-page-item-teaser-font-size: 12pt;
$search-page-item-link-font-size: 12pt;
$search-page-item-link-font-color: $theme-black;
$search-page-item-link-font-color-hover: lighten($body-link-font-color, 42.5%);
;

/* search for news */
// - item
$search-news-item-background-color: $theme-white;
$search-news-item-border: none;
$search-news-item-title-font-color: $theme-black;
$search-news-item-title-font-size: 15pt;
$search-news-item-date-font-color: $theme-black;
$search-news-item-date-font-size: 12pt;
$search-news-item-teaser-font-color: $theme-black;
$search-news-item-teaser-font-size: 12pt;
$search-news-item-link-font-size: 12pt;
$search-news-item-link-font-color: $theme-black;
$search-news-item-link-font-color-hover: $theme-black;

/* search for user */
// - item
$search-user-item-background-color: $theme-white;
$search-user-item-border: 1px solid $theme-black;
$search-user-item-title-font-color: $theme-black;
$search-user-item-title-font-size: 15pt;
$search-user-item-teaser-font-color: $theme-black;
$search-user-item-teaser-font-size: 12pt;
$search-user-item-link-font-size: 12pt;
$search-user-item-link-font-color: $theme-black;
$search-user-item-link-font-color-hover: $theme-black;

/* elearning */
// - common
// -- header row
$elearning-wrapper-title-background-color: transparent;
$elearning-wrapper-title-font-color: $theme-black;
$elearning-wrapper-title-font-size: 21pt;
// -- header row
$elearning-wrapper-header-font-size: 18pt;
$elearning-wrapper-header-font-color: $theme-black;
$elearning-wrapper-header-background-color: $theme-white;
$elearning-wrapper-header-icon-font-size: 15pt;
$elearning-wrapper-header-icon-font-color: $theme-black;
$elearning-wrapper-header-icon-background-color: $theme-white;
$elearning-wrapper-header-border-bottom-color: $border-color;
// --- breadcrumbs
$elearning-wrapper-breadcrumbs-background-color: $theme-white;
// ---- step (Zwischenschritt)
$elearning-wrapper-breadcrumbs-font-size: 12pt;
$elearning-wrapper-breadcrumbs-font-color: $theme-black;
$elearning-wrapper-breadcrumbs-icon-font-size: 12pt;
$elearning-wrapper-breadcrumbs-icon-font-color: $theme-black;
// ---- current step (Letztes Element im Breadcrumb (zB aktueller Seitenname))
$elearning-wrapper-breadcrumbs-current-font-size: 12pt;
$elearning-wrapper-breadcrumbs-current-font-color: $theme-black;
// - course widgets
// -- header row
$elearning-coursewidget-head-font-size: 12pt;
$elearning-coursewidget-head-font-color: $theme-red;
$elearning-coursewidget-head-background-color: $theme-white;
$elearning-coursewidget-head-border-bottom: 5px solid $theme-green;
// -- content row
$elearning-coursewidget-content-font-size: 12pt;
$elearning-coursewidget-content-font-color: $theme-black;
$elearning-coursewidget-content-background-color: $theme-white;
$elearning-coursewidget-content-icon-font-color: $theme-black;
$elearning-coursewidget-content-icon-font-background-color: $theme-white;
// -- footer row
$elearning-coursewidget-footer-font-size: 12pt;
$elearning-coursewidget-footer-font-color: $theme-black;
$elearning-coursewidget-footer-background-color: $theme-white;
// -- chapter
$elearning-coursewidget-chapter-border-bottom: 5px solid $theme-black;
$elearning-coursewidget-chapter-icon-play-color: $theme-green;
$elearning-coursewidget-chapter-icon-refresh-color: $theme-black;
$elearning-coursewidget-chapter-icon-circle-color: $color-gray8;
$elearning-coursewidget-chapter-icon-circleo-color: $color-gray8;
$elearning-coursewidget-chapter-icon-check-color: $theme-black;
// -- learn
$elearning-learn-button-font-color: $theme-black;
$elearning-learn-button-background-color: transparent;

/* profile */
$profile-backgroundimage-border: $theme-black;

/* footer Region */

/** +++ Sight Header Menu +++ */
$sight_header_menu_background: #fff;
$sight_header_menu_text_color: $standard-text-color;
$sight_header_menu_category_separator_color: $secondary-color;
$sight_header_menu_checkbox_border_color: $light-text-color;
$sight_header_menu_checkbox_check_color: $light-text-color;
$sight_header_menu_checkbox_background: $sight_header_menu_background;
$sight_header_menu_checkbox_check_background: $sight_header_menu_background;
$sight_header_menu_tree_background: $sight_header_menu_checkbox_border_color;

/** Levelsystem */
$color-progress-level: #dcaae9;;
$color-progress-bar: #561a66;

/** Levelsystem */
$color-progress-level: #dcaae9;;
$color-progress-bar: #561a66;