@mixin border-radius($top: 10px, $right: $top, $bottom: $top, $left: $right) {
  border-radius: $top $right $bottom $left;
  -moz-border-radius: $top $right $bottom $left;
  -webkit-border-top-left-radius: $top;
  -webkit-border-top-right-radius: $right;
  -webkit-border-bottom-right-radius: $bottom;
  -webkit-border-bottom-left-radius: $left;
}

@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $spread $color;
    -moz-box-shadow: inset $top $left $blur $spread $color;
    box-shadow: inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin transition($params) {
  -webkit-transition: $params;
  -moz-transition: $params;
  -ms-transition: $params;
  -o-transition: $params;
  transition: $params;
}

@mixin no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    list-style-image: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin clean-table {
  table {
    border: none tbody, thead, th, tr, td {
      border: none
    }
    tr {
      background-color: transparent
    }
  }
}

@mixin square($value, $unit: px) {
  width: $value + $unit;
  height: $value + $unit
}

@mixin transform($arguments...) {
  -webkit-transform: $arguments; /* Chrome, Opera 15+, Safari 3.1+ */
  -moz-transform: $arguments; /* Firefox 3.5+ */
  -ms-transform: $arguments; /* IE 9 */
  -o-transform: $arguments; /* Opera 10.5+ */
  transform: $arguments; /* Firefox 16+, IE 10+, Opera */
}

@mixin translate($x:0, $y:0) {
  @include transform(translate($x, $y));
}

@mixin vcenter($translateY: -50%) {
  @include transform(translate(0, $translateY));
  position: relative;
  top: 50%;
}

@mixin round-border {
  box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;
  -webkit-box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;
  -moz-box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;
  border-radius: 32px;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  &:hover, &:focus {
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
    -webkit-box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;

  }

}

/* -------------- MIXINS -------------- */
@mixin gradient($from: $header-gradient-from-background-color, $to: $header-gradient-to-background-color, $deg: 45deg) {
  @if $header-gradient-from-background-color != 'transparent' AND $header-gradient-to-background-color != 'transparent' {
    background: $from; /* Old browsers */
    background: -moz-linear-gradient($deg, rgba($from, 1) 0%, rgba($to, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient($deg, rgba($from, 1) 0%, rgba($to, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient($deg, rgba($from, 1) 0%, rgba($to, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$from,GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
  }
}

//To make Text Ellipse
@mixin ellipse {
  white-space: normal;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;

}

//Appearance
@mixin appearance(
  $val) {
  -webkit-appearance: $val;
  -moz-appearance: $val;
  -ms-appearance: $val;
  appearance: $val;
}

//ICONS MIXINS
//Different icon-sizes
@mixin icon-size($font-size) {
  &:before {
    font-size: $font-size;
  }

}

//icon-variant
@mixin icon-variant($color) {
  &:before {
    color: $color;
  }
  &[href] {
    &:hover,
    &:focus {
      &:before {
        color: darken($color, 10%);
      }
    }
  }

}

@mixin make-iconx() {
  position: relative;
  text-decoration: none;
  text-align: left;
  &:before {
    position: absolute;
    font: normal normal normal #{$body-icon-font-size}/1 FontAwesome;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    font-size: 26px;
    top: 50%;
  }
  &.iconx-before {
    &:before {
      left: 1px;
    }
    &.btn, &[href] {
      padding-left: 32px;
    }
  }
  &.iconx-after {
    &:before {
      right: 1px;
    }
    &.btn, &[href] {
      padding-right: 32px;
    }
  }
  &.iconx-only {
    font-size: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &.btn {
      height: 24px;
      width: 24px;
    }
    &:before {
      left: 0;
      font-size: 19px;
      right: 0;
      width: 100%;
      height: 100%;

    }
    & ~ & {
      margin-left: 30px;
    }
  }
  &.iconx-xs {
    @include icon-size(17px);
  }
  &.iconx-sm {
    @include icon-size(18px);
  }
  &.iconx-lg {
    @include icon-size(21px);
  }
}

*[class*="iconx-"] {
  @include make-iconx();
}

//icon content
@mixin add-icon($icon) {
  &:before {
    content: $icon;
  }
}

//icon content
@mixin add-icon($icon,$font-family) {
  &:before {
    content: $icon;
    font-family: $font-family;
  }
}

//Different Types of Icons States
//.iconx-default {
//  @include icon-variant($icon-default-bg);
//}
//
//.iconx-primary {
//  @include icon-variant($icon-primary-bg);
//}
//
//.iconx-success {
//  @include icon-variant($icon-success-bg);
//}
//
//.iconx-info {
//  @include icon-variant($icon-info-bg);
//}
//
//.iconx-warning {
//  @include icon-variant($icon-warning-bg);
//}
//
//.iconx-danger {
//  @include icon-variant($icon-danger-bg);
//}


