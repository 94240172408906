@import "../_global_includes.scss";

/* ----------- FRONT PAGE----------- */

.widget-courses .widget-course {
  margin-bottom: 5px;
}

body.site.index {
  .front-page-widget-notification-wrapper,
  .front-page-widget-favorites,
  .link-widget {
    margin-bottom: 30px;
  }

  .front-welcome-image {
    //margin-left: -15px;
    //margin-right: -15px;
    margin-bottom: 20px;

    .item {
      background: $theme-black;
      text-align: center;

      video {
        vertical-align: top;
      }
    }
  }

  //Handle video height on variable size
  #page-content-wrapper {
    //&.col-md-9.col-lg-10 {
    //  .front-welcome-image {
    //    .item{
    //      height:278px;
    //
    //      @media (max-width: $screen-md-max) {
    //        height:140px;
    //      }
    //      @media (max-width: $screen-sm-max) {
    //        height:160px;
    //      }
    //      @media (max-width: $screen-xs-max) {
    //        height:130px;
    //      }
    //    }
    //
    //    //video {
    //    //  height: 279.5px;
    //    //  @media (max-width: $screen-xs-max) {
    //    //    height:auto;
    //    //  }
    //    //}
    //  }
    //}
    //&.col-md-11.col-lg-11 {
    //  .front-welcome-image {
    //    .item{
    //      height:308px;
    //      @media (max-width: $screen-md-max) {
    //        height:158px;
    //      }
    //      @media (max-width: $screen-sm-max) {
    //        height:160px;
    //      }
    //      @media (max-width: 767px) {
    //        height:190px;
    //      }
    //      @media (max-width: 450px) {
    //        height:130px;
    //      }
    //    }
    //
    //    //video {
    //    //  height: 311px;
    //    //  @media (max-width: $screen-xs-max) {
    //    //    height:auto;
    //    //  }
    //    //}
    //  }
    //}
  }

  #page-content-wrapper {
    //padding-left: 0;
    //padding-right: 0;
    margin-right: -1px;

    > .container-fluid {
      background: transparent;
      margin: inherit;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .modal-backdrop {
    background-color: $placeholder-color;
  }

  .modal-backdrop.in {
    opacity: 0.8;
  }

  .carousel-indicators {
    top: 5px;
    //bottom: 0;
    right: 25px;
    text-align: right;
    left: auto;
    @media (max-width: $screen-xs-max) {
      right: 0;
      text-align: center;
      left: 0;
      margin: auto;
    }
  }

  .header-news-wrapper {
    margin-bottom: 15px;
    padding: 15px;
    background-color: $theme-white;
    -webkit-box-shadow: 2px 2px 5px 0px $theme-black-dark;
    -moz-box-shadow: 2px 2px 5px 0px $theme-black-dark;
    box-shadow: 2px 2px 5px 0px $theme-black-dark;

    img {
      width: 100%;
      height: auto;
    }

    .carousel-control.left,
    .carousel-control.right {
      font-size: 40px;
      background-image: none;
      filter: none;
    }
  }

  .link-widget,
  .front-page-widget {
    margin-bottom: 15px;
  }
}

.front-wellcome-image {
  padding: 10px;
  background-color: $placeholder-color;
  margin-left: 15px;
  margin-right: 15px;

  img {
    width: 100%;
  }
}

body.site.index #startModal {
  .modal-content {
    border-radius: 0;
    position: relative;

    .close {
      right: 5px;
      top: 5px;
      position: absolute;
      color: $theme-white;
    }
  }
}

.front-page-widget {
  background: $theme-white;
  padding-bottom: 10px;

  .widget-headline-title {
    color: $front-widget-head-font-color;
    font-size: $front-widget-head-font-size;

    i {
      height: 100%;
      width: 2em;
      background-color: $front-widget-head-icon-background-color;
      color: $front-widget-head-icon-font-color;
      font-size: $front-widget-head-icon-font-size;
      padding: 0.45em;
      margin-right: 10px;
      position: absolute;

      &:before {
        margin: auto;
      }
    }

    h3 {
      background-color: $front-widget-head-background-color;
      margin: 0;
      padding: 7px 0 7px 50px;
      float: none !important;
      line-height: 27px;
      font-size: inherit;
    }

  }

  .widget-content {
    color: $front-widget-content-font-color;
    min-height: 100px;
    padding: 5px;

    textarea {
      border-radius: 0px;
      background-color: $placeholder-color;
      resize: none;
    }
  }

  .widget-content-row {
    margin: 0 15px;
    padding: 0.8em 0;
    border-bottom: 1px dotted $border-color;

    > div:last-child {

    }
  }

  .widget-content-row:last-child {
    border-bottom: none;
    padding-bottom: 20px;
  }

  .widget-content-row-date {
    padding: 0 5px;
    color: $front-widget-content-date-font-color;
    font-size: $front-widget-content-date-font-size;

    &.text-danger {
      color: $color-danger;
    }
  }

  .widget-content-row-text {
    padding: 0 5px;

    a:hover {
      text-decoration: none;
      //color: $theme-blue;
    }
  }

  .form-group:last-child {
    margin-bottom: 0px;
  }

  .widget-footer {
    color: $front-widget-footer-font-color;
    font-size: $front-widget-footer-font-size;
    background-color: $front-widget-footer-background-color;
    margin: 0;
    text-align: center;
  }

  .widget-footer a {
    color: inherit;
    padding: 0.6em 0;
    box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;
    border-radius: 32px;
    display: block;

    &:hover, &:focus {
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
      -webkit-transition-property: box-shadow;
      transition-property: box-shadow;
      box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
    }
  }

  .widget-footer .btn:focus,
  .widget-footer .btn:active,
  .widget-footer .btn:hover,
  .widget-footer .btn {
    //font-size: 1.1em;
    color: $front-widget-footer-font-color-hover;
    background-color: transparent;
    outline: none;
  }
}

.front-page-widget-special {
  background: $theme-blue;

  .widget-headline-title {
    color: $front-widget-special-head-font-color;
    font-size: $front-widget-special-head-font-size;

    i {
      height: 2em;
      width: 2em;
      background-color: $front-widget-special-head-icon-background-color;
      color: $front-widget-special-head-icon-font-color;
      font-size: $front-widget-special-head-icon-font-size;
      padding: 0.45em;
      margin-right: 10px;
      position: relative;

    }

    h3 {
      background-color: $front-widget-special-head-background-color;
      margin: 0;
      padding: 0;
      float: none !important;
      line-height: 27px;
      height: auto;
      font-size: inherit;
      padding-top: 7px;
      //padding-bottom: 32px;
    }
  }

  .widget-content {
    color: $front-widget-special-content-font-color;
    min-height: 100px;
    padding: 5px;

    textarea {
      border-radius: 0;
      resize: none;
    }
  }

  .widget-content-row {
    margin: 0 15px;
    padding: 0.8em 0;
    border-bottom: 1px dotted $border-color;

    > div:last-child {

    }
  }

  .widget-content-row:last-child {
    border-bottom: none;
    padding-bottom: 20px;
  }

  .widget-content-row-date {
    color: $front-widget-special-content-date-font-color;
    font-size: $front-widget-special-content-date-font-size;
    padding: 0 5px;

    &.text-danger {
      color: $color-danger;
    }
  }

  .widget-content-row-text {
    padding: 0 5px;

    a:hover {
      text-decoration: none;
      //color: $theme-blue;
    }
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .widget-footer {
    color: $front-widget-special-footer-font-color;
    font-size: $front-widget-special-footer-font-size;
    background-color: $front-widget-special-footer-background-color;
    margin: 0;
  }

  .widget-footer a {
    color: inherit;
    box-shadow: inset 0 0 0 1px $theme-red, 0 0 1px transparent;
  }

  .widget-footer .btn:focus,
  .widget-footer .btn:active,
  .widget-footer .btn:hover,
  .widget-footer .btn {
    //font-size: 1.1em;
    color: $front-widget-special-footer-font-color-hover;
    background-color: transparent;
    outline: none;
  }
}

.widget-middle {
  padding: 5px 10px;
}

.widget-middle-title {
  background: transparent;
  min-height: 2vw;
  color: $front-widget-footer-font-color;
  display: block;
  margin: -10px 0 0 0;
  padding: 1em 0;
}

.link-widget.front-page-widget-special {
  background: $front-widget-link-special-background-color;

  .widget-middle-title {
    color: $front-widget-special-footer-font-color;
  }
}

.link-widget {
  position: relative;
  background: $front-widget-link-background-color;

  .widget-middle-image {
    position: relative;
    display: block;
    padding: 10px;
    padding-top: 20px;
  }

  .widget-icon {
    font-size: $front-widget-text-icon-font-size;
    color: $front-widget-text-icon-font-color;
  }
}

.front-page-widget-notices .widget-footer {
  padding: 0;
}

.front-page-widget-elearnings .widget-content-row-date i {
  font-size: 20px;
  background-color: $placeholder-color;
  padding: 8px 12px;
}

.front-page-widget-profile {
  .text-circle-user {
    width: 100px;
    height: 100px;
    background-size: cover;
    margin: 0 auto;
    @include border-radius(50%);
  }

  .widget-profile-name {
    margin-top: 20px;
    font-size: 2em;
  }
}

.front-page-widget-readings {
  min-height: 100px;
}

/* ----------- BLOG ----------- */
.blog {
  h1 {
    font-weight: bold;
    border-bottom: 1px solid $border-color;
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 1.6em;

    i {
      color: $placeholder-color;
      padding: 5px;
      margin-right: 1em;
    }
  }

  .user-picture {
    .img-circle {
      width: 50px;
      height: 50px;
      border: 1px solid $placeholder-color;
    }
  }
}

// products widget
.front-page-widget-products {

  .widget-headline-title {
  }

  .widget-headline-title h3.pull-left {
    background-color: $color-gray14;
    margin-top: 0;
    padding-top: 0.4em;
    padding-bottom: 0;
  }

  .widget-headline-title i {
    height: 2em;
    width: 2em;
    background-color: $theme-blue;
    color: $theme-white;
    font-size: 1.3em;
    padding: 0.45em;
    margin-right: 10px;
  }

  h3 {
    border-bottom: 1px solid $border-color;
    font-size: 1.4em;
    margin: 0;
    padding: 0;
    margin-top: 0.6em;
    float: none !important;
    //height: 1.6em;
    //border-bottom: 1px solid $grey-2;
    line-height: 26px;
  }

  h4 {
    border-bottom: 1px solid $border-color;
    font-size: 1.4em;
    margin: 0;
    padding: 0;
    margin-top: 0.6em;
    float: none !important;
    height: 1.6em;
  }

  .widget-content {
    min-height: 100px;
    //height:150px;
    //padding-right: 15px;
    //padding-left: 15px;
  }

  .widget-content-row {
    margin: 0 0;
    padding: 0.8em 0;
    // border-bottom: 1px solid $grey;
    border-bottom: 1px solid $border-color;

    > div:last-child {
    }
  }

  .widget-content-row:last-child {
    border-bottom: none;
  }

  .widget-content-row-date {
    //color: $widget7;
    color: $theme-black;
    font-size: 0.8em;

    &.text-danger {
      color: $color-danger;
    }
  }

  .widget-content-row-text {

  }

  .widget-footer {
    //background-color: $widget3;
    background-color: $theme-blue;
    margin: 0;
    padding: 1em 0;
  }

  .widget-footer a {
    //color: $menu-font-color;
    color: $theme-white;
  }
}

.front-page-widget-products {
  //background-color: $white;
  background: transparent;
  border: 0;
  box-shadow: none;

  .widget-headline-title {
    //margin:0px;
    //padding: 0px;
  }

  .front-page-widget-products-inner {
    background-color: $theme-white;
    //min-height: 134px;
  }

  .widget-content {
    //min-height: 134px;;
    img {
      width: 100%;
      height: auto;
    }
  }
}

//Notices
.front-page-widget-notices {
  background: $theme-white;
  margin-bottom: 15px;

  .widget-headline-title h3.pull-left {
    background-color: $theme-white;
    margin-top: 0;
    padding-top: 0.4em;
    padding-bottom: 0;
  }

  .widget-headline-title i {
    height: 2em;
    width: 2em;
    background-color: $theme-blue;
    color: $theme-white;
    font-size: 1.3em;
    padding: 0.45em;
    margin-right: 10px;
  }

  h3 {
    font-size: 1.4em;
    margin: 0;
    padding: 0;
    margin-top: 0.6em;
    float: none !important;
    color: $front-widget-head-font-color;
    //height: 1.6em;
    //border-bottom: 1px solid $grey-2;
    line-height: 26px;
  }

  h4 {
    border-bottom: 1px solid $border-color;
    font-size: 1.4em;
    margin: 0;
    padding: 0;
    margin-top: 0.6em;
    float: none !important;
    height: 1.6em;
  }

  .widget-content {
    min-height: 100px;

    img {
      //width: 100%;
    }
  }

  .widget-content-row {
    margin: 0 0;
    padding: 0.8em 0;
    // border-bottom: 1px solid $grey;
    border-bottom: 1px solid $border-color;
    margin-left: 15px;
    margin-right: 15px;

    > div:last-child {
    }
  }

  .widget-content-row:last-child {
    border-bottom: none;
  }

  .widget-content-row-date {
    //color: $widget7;
    color: $theme-black;
    font-size: 0.8em;

    &.text-danger {
      color: $color-danger;
    }
  }

  .widget-content-row-text {

  }

  .widget-footer {
    //background-color: $widget3;
    background-color: $btn-background-color;
    margin: 0;
    padding: 1em 0;

    .btn-block {
      background-color: $btn-background-color;
      color: $btn-font-color;
      border: 1px solid $border-color;
    }
  }

  .widget-footer a {
    //color: $menu-font-color;
    color: $theme-white;
  }
}

.front-page-widget-notices .widget-footer {
  padding: 0;
}

.front-page-widget-notices .form-group {
  margin-bottom: 0px;
}

/* ----------- NEWS ----------- */
.front-page-widget-news {
  .widget-headline-title {
    //display: none;
  }

  .news-footer {
    display: none;
  }

  .news-teaser-list {
    .news-headline-title {
      display: none;
    }
  }
}

.list-view {
  padding: 0 1em;

  .news-list-article {
    border-bottom: 1px dotted $theme-black;
    padding: 0.5em 0;

    &:last-of-type {
      border: 0;
    }

    .display-none {
      display: none;
    }

    .news-teaser-date {
      color: $color-gray9;
    }
  }
}

.front-page-widget-news-head {
  .news-text-wrapper {
    background-color: $theme-white;
    @media (min-width: $screen-lg-min) {
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      opacity: 0.95;
      padding: 0 15px;
      height: 90px;
    }
    @media (max-width: $screen-md-max) {
      padding: 10px 5px;
      height: 110px;
    }
    @media (max-width: $screen-xs-max) {
      height: 135px;
    }

    h3 {
      color: $theme-white;
      height: 20px;
      overflow: hidden;

    }

    a {
      color: $theme-white;
    }

    p > a {
      color: $theme-black;
    }
  }

  .news-teaser-image {
    @media (min-width: $screen-lg-min) {
      height: 350px !important;
    }
    @media (max-width: $screen-md-max) {
      height: 170px !important;
    }
    @media (max-width: $screen-xs-max) {
      height: 140px !important;
    }

    img {
      height: 100% !important;
      width: 100%;
    }

  }

}

.news {
  h1 {
    margin-bottom: 20px;
    padding-bottom: 0;
    font-size: 1.6em;
    padding-left: 10px;

    i {
      color: $placeholder-color;
      padding: 5px;
      margin-right: 1em;
    }
  }

  .date {
    text-align: right;
    margin-top: 0.7em;
  }
}

//Planning
.front-page-widget-planning {
  background-color: transparent;

  .widget-content {
    padding: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li {
      border-bottom: 1px solid $border-color;

      a {
        padding: 5px 20px 5px 50px;
        display: block;
        color: $theme-white;
        background-color: $theme-red;
        position: relative;

        &:hover, &:focus {
          background-color: darken($theme-red, 6.7%);

        }

      }

      .icon {
        position: absolute;
        left: 20px;

        i {

          color: $theme-white;
        }
      }
    }
  }
}

//Elearnings

.front-page-widget-elearnings .widget-content-row-date i {
  font-size: 20px;
  background-color: $theme-white;
  padding: 8px 12px;
}

.front-page-widget-elearnings {
  margin-bottom: 15px;
  background-color: $theme-white;
  padding-bottom: 10px;

  .widget-headline-title i {
    height: 2em;
    width: 2em;
    background-color: $theme-white;
    color: $theme-black;
    font-size: 1.5em;
    padding: 0.45em;
    margin-right: 10px;
  }

  .widget-headline-inner {
    background-color: $theme-white;
  }

  h3 {
    font-size: 1.4em;
    margin: 0;
    float: none !important;
    height: 1.6em;
    white-space: nowrap;
    background-color: $theme-white;
    padding: 0;
    line-height: 27px;
    padding-top: 7px;
    padding-bottom: 32px;
    color: $heading-color;

  }

  .widget-content {
    min-height: 200px;
  }

  .widget-content-row {
    margin: 0 15px;
    padding: 0.8em 0;
    border-bottom: 1px dotted #000000;
    border-bottom: 1px dotted $theme-black;
    //border-bottom: 1px solid $border-color;
    > div:last-child {

    }
  }

  .widget-content-row-date {
    color: $theme-black;
    font-size: 0.8em;

    a {
      color: $theme-black;
    }

    &.text-danger {
      color: $color-danger;
    }
  }

  .widget-footer {
    margin: 0;
    color: $theme-white;
    font-size: 12pt;
    background-color: $btn-background-color;

    a {
      color: $btn-font-color;
      border-radius: 32px;
      display: block;
      padding: 0.6em 0;
      box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;

      &:hover, &:focus {
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        -webkit-transition-duration: 0.1s;
        transition-duration: 0.1s;
        -webkit-transition-property: box-shadow;
        transition-property: box-shadow;
        box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
      }
    }
  }
}

.front-page-widget-elearnings .widget-content-row-date i {
  font-size: 20px;
  background-color: $theme-white;
  padding: 8px 12px;
}

#news-content {
  min-height: 500px;
}

body.site .block {
  position: relative;

  .panel-group {
    .panel-default > .panel-heading {
      background: $content-wrapper-accordion-head-background;
      color: $content-wrapper-accordion-head-color;
      font-size: $content-wrapper-accordion-head-font-size;
      padding-top: 7px;
      padding-bottom: 7px;

      a:active,
      a:hover,
      a {
        color: $content-wrapper-accordion-head-color;
      }
    }

    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      background: $content-wrapper-accordion-content-background;
      color: $content-wrapper-accordion-content-color;
      font-size: $content-wrapper-accordion-content-font-size;
      border: none;
    }
  }

}

body.site .block iframe {
  min-height: 500px;
}

.login_panel {
  padding-top: 10%;
  min-height: 650px;
}

#login-form .buttons {
  position: relative;
}

#login-form .buttons a {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  left: 10px;
}

.resetpassword .panel-form {
  background: url(../../images/theme/passwort_zuruecksetzen.png) no-repeat;
  background-position: right top;
  background-color: $placeholder-color;
  min-height: 30em;
}

body.site.content .content-content {
  border-right: $border-color solid 1px;
}

.content-content img {
  max-width: 100%;
  height: auto;
}

.news-teaser-list {

}

.news-teaser-list .news-teaser-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: none;
  //padding: 0px 15px 15px 15px;
}

.news-teaser-list h1 {
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.news-teaser-list h2 {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 0;
}

.news-teaser-list .teaser-image {
  margin-bottom: 15px;
  text-align: center;
}

.news-teaser-list .teaser-image img.thumbnail {
  display: inline;
}

.news-teaser-date .text-info {
  color: $placeholder-color;
}

/** News Filter **/
div.tag {
  padding: 5px 10px;
  font-size: 14px;
  border: solid thin $border-color;
  background-color: $placeholder-color;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

div.tag.active {
  background-color: $placeholder-color;
  border-color: $border-color;
  font-weight: 700;
  padding-right: 15px;
}

div.tag.active::before {
  color: $placeholder-color;
  @include fa-icon(times);
  font-size: 14px;
  right: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

div.word {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: $placeholder-color;
}

.content-content h1 {
  font-size: 25px;
}

.content-content h2 {
  font-size: 18px;
}

#public-news {
  form {
    padding-top: 22px;
  }
}

#news {
  border: none;
  min-height: 600px;

  .content-news {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .news-teaser-list {
    h3 {
      padding: 0;
      padding-bottom: 0.2em;
      float: none !important;
    }

    .news-list-article {
      border-bottom: $content-sidebar-item-border-bottom;
      //margin-bottom: 10px;
      margin-left: 0;
      padding: 10px;
    }
  }

  .news-list-article {
    .news-teaser-date {
      color: $news-item-date-font-color;
      font-size: $news-item-date-font-size;
    }

    h3 {
      line-height: 1.1em;
      font-size: $news-item-title-font-size;
      color: $news-item-title-font-color;
      margin: 3px 0;
    }

    .news-teaser-text {
      font-size: $news-item-teaser-font-size;
      color: $news-item-teaser-font-color;
    }
  }

  #form-filter-search {
    padding-top: 32px;

    .btn-default {
      border-radius: 0;
    }

    .button-row {
      margin-top: 5px;
    }
  }
}

#page-content-wrapper {
  min-height: 500px;

  .panel {
    //    border: none;
    box-shadow: none;
  }
}

.feedback-section,
.comment-section {
  border-top: 5px solid $content-wrapper-header-background-color;
  padding-top: 15px;
  padding-bottom: 15px;

  .form-control {
    margin-bottom: 15px;
  }
}

.news-meta-wrapper,
.blog-meta-wrapper {
  margin-top: 20px;
  background-color: $placeholder-color;
  padding: 3px 0;
}

.content-content .leistung,
.content-content .leistung {
  background-size: contain;
  padding-left: 40px;
  background-image: url("../../images/theme/daumen.png");
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
}

.content-content .ueberblick,
.content-content .ueberblick {
  background-size: contain;
  padding-left: 40px;
  background-image: url("../../images/theme/auge.png");
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
}

.content-content .dokument,
.content-content .dokument {
  background-size: contain;
  padding-left: 40px;
  background-image: url("../../images/theme/dokument.png");
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
}

.content-content .smile,
.content-content .smile {
  background-size: contain;
  padding-left: 40px;
  background-image: url("../../images/theme/smile.png");
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
}

#course-grid h4.panel-title span.pull-right {
  font-size: 16px;
}

.block-expired {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  border: 1px solid transparent;
  opacity: 0.9;
  filter: alpha(opacity=90); /* For IE8 and earlier */
}

.public-page-expired-notification .content-dynamic > .row,
.block-expired-notification.block-expired > .row {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.public-page-expired-notification .content-dynamic > .row > .alert-danger,
.block-expired-notification.block-expired > .row > .alert-danger {
  text-align: center;
  font-weight: 700;
}
.page-read-command {
  margin-top: 20px;

  .alert-danger {
    .row {
      display: flex;
      align-items: center;

      div.col-xs-8 {
        //color: $primary-color;
      }
    }

    > div.info-text {
      margin-bottom: 1rem;
      text-align: center;
    }
  }
}
.block-expired:hover {
  border-color: $border-color;
  opacity: 1;
  filter: alpha(opacity=100); /* For IE8 and earlier */
}

.public-page-expired-notification .content-dynamic,
.block-expired-notification {
  height: 200px;
  background: url(../../images/theme/block-expired-notification.png) no-repeat center center;
  background-size: cover;
  background-color: $placeholder-color;
  opacity: 0.9;
  filter: alpha(opacity=90); /* For IE8 and earlier */
}

.public-page-expired-notification .content-dynamic {
  height: 400px;
}

.content-dynamic {
  padding-left: 39px;
  padding-right: 39px;
}

.page-edit-button-group a > i,
.block-edit-button-group a > i {
  margin-right: 5px;
}

.block-edit-button-group {
  position: absolute;
  right: 0;
  top: -0;

  p {
    //   padding: 3px 20px;
  }

  .dropdown-menu > li {
    margin-left: 15px;

    > p {
      padding: 0;
    }

    &.dropdown-header {
      padding-left: 0;
    }

    a {
      color: $content-wrapper-menu-link-font-color;
      background-color: $content-wrapper-menu-link-background-color;
      font-size: $content-wrapper-menu-link-font-size;
      position: relative;
      padding-left: 25px;

      .fa, .far, .fas {
        color: $content-wrapper-menu-link-icon-font-color;
        font-size: $content-wrapper-menu-link-icon-font-size;
        position: absolute;
        left: 0;
      }

      &:hover {
        color: $content-wrapper-menu-link-font-color-hover;
        background-color: $content-wrapper-menu-link-background-color-hover;

        .fa, .far, .fas {
          color: $content-wrapper-menu-link-icon-font-color-hover;
        }
      }
    }
  }
}

.block-edit-button-group > button {
  z-index: 1;
}

.block-edit-button-group .dropdown-menu {
  z-index: 2;
}

.block-edit-active {
  border: $placeholder-color 1px dashed;
}

#page-content-wrapper {
  .panel-body {
    ul:not(.dropdown-menu):not(.nav-tabs),
    ol {
      padding-left: 18px;
      list-style-position: outside;
      margin-bottom: 20px;
    }

    ul ul,
    ul ol,
    ol ul,
    ol ol {
      margin-bottom: 0;
    }

    ul:not(.dropdown-menu):not(.nav-tabs) {
      list-style-type: disc;
    }

    p {
      margin: 0 0 20px 0;
    }
  }

  //ul.nav-tabs li a {
  //  background: $search-header-tab-background-color;
  //  color: $search-header-tab-font-color;
  //  font-size: $search-header-tab-font-size;
  //}
  //ul.nav-tabs li a:hover {
  //  background: $search-header-tab-background-color-hover;
  //  color: $search-header-tab-font-color-hover;
  //}
  //ul.nav-tabs li.active a {
  //  background: $search-header-tab-active-background-color;
  //  color: $search-header-tab-active-font-color;
  //  font-size: $search-header-tab-active-font-size;
  //}
  //ul.nav-tabs li.active a:hover {
  //  background: $search-header-tab-active-background-color-hover;
  //  color: $search-header-tab-active-font-color-hover;
  //}
  .date-create {
    color: $placeholder-color;
  }

  .search-results {
    .nav-tabs {
      li {
        margin-bottom: 5px;
      }
    }

    .search-tags > div {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px #a1a1a1 solid;
    }
  }

  .search-results-pages {
    .panel-kms {
      background-color: $search-page-item-background-color;
      border-radius: 0;
      border: $search-page-item-border;
    }

    h4,
    h3:first-child {
      color: $search-page-item-title-font-color;
      font-size: $search-page-item-title-font-size;
    }

    .date-create {
      color: $search-page-item-date-font-color;
      font-size: $search-page-item-date-font-size;
      @media (max-width: $screen-xs-max) {
        font-size: 10pt;
        float: right;
        color: $color-gray-base;
      }
    }

    .panel-heading {
      padding: 10px 15px 0px;
    }

    .panel-body {
      color: $search-page-item-teaser-font-color;
      font-size: $search-page-item-teaser-font-size;
      padding: 0 15px 10px;
    }

    li {
      &.selected {
        a {
          color: $theme-black;
          @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
          }
        }
      }
    }

    a {
      font-size: $search-page-item-link-font-size;
      color: $search-page-item-link-font-color;
      $search-page-item-link-font-color-hover: $theme-black;
    }

    a:hover,
    a:focus {
      color: $search-page-item-link-font-color-hover;

      h4, .highlight {
        color: $search-page-item-link-font-color-hover;
      }
    }
  }

  .search-results-content {
    .panel-kms {
      background-color: $search-news-item-background-color;
      border-radius: 0;
      border: $search-news-item-border;
    }

    h4,
    h3:first-child {
      color: $search-news-item-title-font-color;
      font-size: $search-news-item-title-font-size;
    }

    .date-create {
      color: $search-news-item-date-font-color;
      font-size: $search-news-item-date-font-size;
      @media (max-width: $screen-xs-max) {
        font-size: 10pt;
        float: none;
      }

    }

    .panel-body {
      color: $search-news-item-teaser-font-color;
      font-size: $search-news-item-teaser-font-size;
    }

    a {
      font-size: $search-news-item-link-font-size;
      color: $search-news-item-link-font-color;
      $search-news-item-link-font-color-hover: $theme-black;
    }

    a:hover,
    a:focus {
      color: $search-news-item-link-font-color-hover;
    }
  }

  .search-results-user {
    color: $search-user-item-teaser-font-color;
    font-size: $search-user-item-teaser-font-size;

    h4,
    h4 > a,
    h3:first-child {
      color: $search-user-item-title-font-color;
      font-size: $search-user-item-title-font-size;
    }

    h4 {
      border-bottom: 1px solid $search-user-item-title-font-color;
      padding: 10px;
    }

    .user-image {
      max-height: 90px;
    }

    .grid-item {
      padding-left: 25px;
      padding-right: 25px;

      span.departments {
        white-space: pre-line;
      }
    }

    .panel {
      border: $search-user-item-border;
      background: $search-user-item-background-color;
    }

    .panel-image {
      padding: 15px;

      a {
        color: $search-user-item-link-font-color;
        font-size: $search-user-item-link-font-size;
      }
    }
  }
}

/* Combobox */
button.ui-button-icon {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 1%;
  background-color: $color-gray14;
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-top: 1px solid $border-color;
  border-radius: 0px;
  color: $theme-black;
  font-weight: 400;
  line-height: 1;
  padding: 6px 17px;
  text-align: center;
}

button.ui-button-icon > i {
  font-size: 20px;
}

/* SPANGLER special */
/* gallery */
.gallery-control {
  img {
    height: 130px;
    width: auto;
    margin: 15px 15px 15px 0;
    cursor: pointer;
    padding: 5px;
    border: 1px solid transparent;

    .active {
      border: 1px solid $border-color;
    }
  }
}

/* dustin container */
.media-container {
  margin-bottom: 15px;
  width: 210px;
  overflow: hidden;
  padding-bottom: 10px;

  a,
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
  }

  a.link-external:after {
    display: none;
  }

  div {
    a,
    a:hover,
    a:active,
    a:visited {
      text-decoration: none;
      color: $theme-white;
    }
  }

  .media-title {
    height: 90px;
    border: 1px solid transparent;
    background: $theme-black;
    color: white;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    -moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  }

  .media-title-text {
    color: white;
    font-weight: bold;
    font-family: Arial;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 25px;
    margin-left: 5px;

    .col-icon {
      i {
        text-decoration: none;
        color: $theme-white;
        margin-right: 5px;
      }
    }

    .col-head {
      word-wrap: break-word;
      padding-bottom: 25px;
    }
  }

  .media-separator {
    height: 7px;
    border: 1px solid transparent;
    background: $theme-green;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    -moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  }

  .media-summary {
    height: 90px;
    border: 1px solid transparent;
    background: $theme-white;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    -moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  }

  .media-summary-text {
    padding: 15px;
    padding-bottom: 5px;
    font-family: Arial;
    font-size: 11px;
    margin-left: 5px;
    color: $theme-black;

    a,
    a:hover,
    a:active,
    a:visited {
      text-decoration: none;
      color: $theme-black;
    }
  }

  .media-extra {
    padding: 0 15px 15px 15px;
    background: $theme-white;
    color: $theme-black;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    -moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);

    > a,
    > a:hover,
    > a:active {
      font-weight: 700;
      font-family: Arial;
      font-size: 11px;
      color: $theme-black;
    }

    > a:before {
      font-family: 'Font Awesome 5 Pro';
      @include fa-icon("arrow-circle-right");
      margin-right: 5px;
      color: $theme-black;
      font-size: 11px;
    }
  }
}

/* icon includes */
.pic-prozess,
.pic-vorgehen,
.pic-dokument {
  position: relative;
  font-size: 15pt;
  margin: 25px 0 15px 0;
  padding: 20px 0 10px 50px;
}

.pic-vorgehen:before {
  content: url('../../images/icon_hand.png');
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pic-prozess:before {
  content: url('../../images/icon_prozess.png');
  position: absolute;
  left: 0;
  top: 50%;

  transform: translateY(-50%);
}

.pic-dokument:before {
  content: url('../../images/icon_blatt.png');
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {

  .front-page-widget .widget-headline-title,
  #sidebar-right .content-news-teaser .news-headline-title h3,
  .front-page-widget-notices .widget-headline-title h3.pull-left,
  .front-page-widget-elearnings h3,
  .front-page-widget-products .widget-headline-title h3.pull-left {
    font-size: $mobile-headings;
  }

  .pic-prozess,
  .pic-dokument,
  .pic-vorgehen {
    padding-left: 0;
    margin-left: 30px;
  }
  .pic-prozess:before,
  .pic-dokument:before,
  .pic-vorgehen:before {
    left: -40px;
  }
  .content-dynamic {
    padding-left: 15px;
    padding-right: 15px;
  }

  body.site.index {
    .link-widget-spangler-wrapper {
      //margin-left: -30px;
      //margin-right: -30px;
    }

    #sidebar-right {
      margin-top: 0;
      margin-left: inherit;
      // width: inherit;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// bootstrap plugin

// collapse expand a tag
div[data-widget='BootstrapCollapse'] {
  .panel-title > a {
    display: block;
    width: 100%;
  }
}

div[aria-multiselectable='true'] {
  .panel-title > a[aria-controls^="collapse"] {
    display: block;
    width: 100%;
  }

  .panel-title > a[data-toggle="collapse"] {
    display: block;
    width: 100%;
  }

  .panel-heading[id^="list-modal-"] {
    margin-bottom: 5px;
    padding: 10px 10px 10px 10px !important;

  }
}

.panel-produkt {
  .panel-content {
    box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
    -webkit-box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    padding: 15px;
  }

}

.produkt-item-info {
  .panel-default {
    .panel-content {
      box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
      -webkit-box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
      -moz-box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
      -webkit-border-radius: 32px;
      -moz-border-radius: 32px;
      border-radius: 32px;
      padding: 15px;
    }
  }
}

.produkt-info.content-inner, .produkt-item-info.content-inner {
  h1 {
    margin-bottom: 10px !important;
  }

  .panel {
    border-radius: 0;
    border: 0;
    box-shadow: none;

    .btn-default {
      border-radius: 0;
      display: block;
      padding: 6px 12px;
      font-size: $elearning-coursewidget-footer-font-size;
      color: $elearning-coursewidget-footer-font-color;
      background: $elearning-coursewidget-footer-background-color;
    }

    .text-circle-icon i.fa:before {
      font-size: 6em;
    }

    .produkt-info-content, .produkt-item-info-content {
      margin-bottom: 15px;

      p {
        strong {
          display: block;
        }
      }

      > div:first-of-type {
        margin-bottom: 10px;
      }

      > div:last-of-type {
        @media (max-width: $screen-sm-max) {
          text-align: center;
        }
      }
    }

    .row {
      > div :first-of-type {
        @media (max-width: $screen-md-max) {
          //margin-bottom: 15px;
        }
      }
    }
  }

  .panel-body {
    border-radius: 0;
    background: $elearning-coursewidget-content-background-color;
    color: $elearning-coursewidget-content-font-color;
    font-size: $elearning-coursewidget-content-font-size;
    border: none;

    h1 {
      margin: 0
    }
  }

  .panel-heading {
    border-radius: 0;
    background: $elearning-coursewidget-head-background-color;
  }

  .thumbnail {
    padding: 0;
    border-radius: 0;
    border: 0;
  }

  .icon {
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 13px 0;
    line-height: 1.428571429;
    border-radius: 50%;
    background: $elearning-coursewidget-content-icon-font-background-color;
    margin: auto;
    @media (max-width: $screen-xs-max) {
      width: auto;
      height: auto;
      padding: 6px 0;
    }

    span.fa, i {
      color: $elearning-coursewidget-content-icon-font-color;
      font-size: 50px;
      padding-top: 0;

      @media (max-width: $screen-xs-max) {
        font-size: 30px;
      }
    }
  }

  .border-above {
    border-top: 1px dotted $border-color;
    padding: 10px 0;

  }

}

.produkt-item-detail {
  h1, p {
    margin-bottom: 10px;
  }

  .item-properties {
    list-style: none !important;
    padding-left: 0 !important;

    li {
      @include round-border;
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 15px;
      background-color: $theme-gray-light;
      box-shadow: none;
      margin-bottom: 10px;

      &:hover, &:focus {
        background-color: $theme-white;
      }

      .details {
        @media (max-width: $screen-xs-max) {
          width: 75%;
          word-wrap: break-word;
        }
      }

      .price {
        @media (max-width: $screen-xs-max) {
          width: 25%;
          word-wrap: break-word;
        }

      }
    }
  }

  //.item-properties {
  //    @include round-border;
  //    border-radius: 5px;
  //    padding: 10px 15px;
  //    background-color: $theme-gray-light;
  //    box-shadow: none;
  //    margin-bottom: 10px;
  //    &:hover,&:focus{
  //      background-color: $theme-white;
  //    }
  //  }
}

.change-width {
  display: none;

  .modal-dialog {
    @media (min-width: $screen-md-min) {
      width: 50% !important;
      .modal-title {
        font-size: 26pt;
      }

    }

  }
}

.modal-header {
  .close {
    opacity: 1;

    i {
      font-size: 20pt;
    }
  }
}

//style classes
.color-green {
  color: $theme-green !important;
}

.bg-light-gray {
  background-color: $theme-gray-light !important;
}

.padding-1x {
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.fa-2x {

}

.margin-left-minus-10x {
  margin-left: -10px;
}

.contact-details {
  .row {
    margin-bottom: 10px;
  }

  .fa {
    color: $theme-green !important;
    font-size: 2em !important;
  }
}

.front-page-widget-award {
  background-color: #0fcbea !important;
  color: white !important;
}

.front-page-widget-award-title {
  color: white !important;
  font-size: 13pt;
  padding-left: 22px;
  padding-top: 12px;
  text-align: right;
}

.front-page-widget-award-box {
  height: 70px;
  min-height: 0 !important;
}

.front-page-widget-award-value {
  color: white !important;
  font-size: 33px;
  padding-left: 0px;
}

.levelsystem-widget-wrapper .widget-footer {
  padding: 0 15px;
}

body.site.reset_password {
  #wrapper>.container {
    background: transparent;
  }
}

#reset-password-link {
  font-size: 1.2em;
  text-align: right;
  display: block;
}